import React ,{useState,useRef} from "react";
import { NavLink} from 'react-router-dom';
import '../css/contactUs.css'
import emailjs from '@emailjs/browser';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
function ContactUs() {
  const form = useRef()
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_ci7uqmj', 'template_6zi5h0i', form.current, {
          publicKey: 'kx-y9KhqWZgdIxqxH',
        })
        .then(
          () => {
            closeModal();
            toastr.success("Email sent successfully");
          },
          (error) => {
            toastr.error(error);
          },
        );
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOverlayClick = (event) => {
      if (event.target.id === 'crud-modal') {
        closeModal();
      }
    };
    const sectionRef = useRef(null);
  const sectionRef1 = useRef(null);
  const handleScroll = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScroll1 = () => {
    if (sectionRef1.current) {
      sectionRef1.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
<div>
<div className='w-full header-section-services' ref={sectionRef}>
    <div className="container mx-auto">
      <header className='pt-8 pb-8 md:px-12 px-4'>
        <nav className="navbar-1 navbar-services bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <a className="flex items-center navbar-heading" href='#'>
            App Dev &nbsp;<span className='navbar-heading-span'>Master.</span>
            </a>
            <div className="flex items-center lg:order-2">
              <button
                onClick={toggleMenu}
                type="button"
                className="toggler-btn inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded={isOpen ? "true" : "false"}
              >
                <span className="sr-only">Open main menu</span>
                <svg className={`w-6 h-6 ${isOpen ? "hidden" : "block"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                </svg>
                <svg className={`w-6 h-6 ${isOpen ? "block" : "hidden"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </button>
            </div>
            <div className={`w-full lg:flex lg:w-auto lg:order-2 ${isOpen ? "block" : "hidden"}`} id="mobile-menu-2">
              <ul className="flex navbar-custom-flex mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <NavLink className="block py-2 pr-4 pl-3" to="/">Home</NavLink>
                </li>
                <li>
                <NavLink className="block py-2 pr-4 pl-3" to="/services">
                Services
          </NavLink>
                </li>
                <li>
                <NavLink className="block py-2 pr-4 pl-3" to="/portfolio">
                Portfolio
                  </NavLink>
                </li>
                <li className="mb-2">
                <a href='#'>
                  <svg xmlns="http://www.w3.org/2000/svg" id="Component_56_1" data-name="Component 56 – 1" width="38" height="38" viewBox="0 0 54 54"><rect id="Rectangle_24465" data-name="Rectangle 24465" width="54" height="54" rx="27" fill="#8748C2"></rect><path id="Path_125981" data-name="Path 125981" d="M34.98,31.956h0v-.009l-1.249,1.242a6.577,6.577,0,0,1-6.217,1.785,21.856,21.856,0,0,1-6.342-2.84A29.327,29.327,0,0,1,16.164,28.1a29.552,29.552,0,0,1-3.741-4.544,23.02,23.02,0,0,1-2.812-5.689,6.577,6.577,0,0,1,1.644-6.718l1.458-1.464a1.045,1.045,0,0,1,1.477,0h0L18.812,14.3a1.045,1.045,0,0,1,0,1.477h0L16.1,18.494a2.211,2.211,0,0,0-.231,2.877,31.732,31.732,0,0,0,3.256,3.8A31.538,31.538,0,0,0,23.4,28.762a2.229,2.229,0,0,0,2.845-.247l2.623-2.664a1.045,1.045,0,0,1,1.477,0h0l4.629,4.643a1.043,1.043,0,0,1,.006,1.465Z" transform="translate(4.524 4.438)" fill="#fff"></path></svg>
                  </a>
                  </li>
                <li>
                <NavLink className="navbar-button block py-2 pr-4 pl-3" to="/contactUs">
                Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className='navbar-custom-center'>
              <div>
                  <svg xmlns="http://www.w3.org/2000/svg" id="Component_56_1" data-name="Component 56 – 1" width="38" height="38" viewBox="0 0 54 54"><rect id="Rectangle_24465" data-name="Rectangle 24465" width="54" height="54" rx="27" fill="#8748C2"></rect><path id="Path_125981" data-name="Path 125981" d="M34.98,31.956h0v-.009l-1.249,1.242a6.577,6.577,0,0,1-6.217,1.785,21.856,21.856,0,0,1-6.342-2.84A29.327,29.327,0,0,1,16.164,28.1a29.552,29.552,0,0,1-3.741-4.544,23.02,23.02,0,0,1-2.812-5.689,6.577,6.577,0,0,1,1.644-6.718l1.458-1.464a1.045,1.045,0,0,1,1.477,0h0L18.812,14.3a1.045,1.045,0,0,1,0,1.477h0L16.1,18.494a2.211,2.211,0,0,0-.231,2.877,31.732,31.732,0,0,0,3.256,3.8A31.538,31.538,0,0,0,23.4,28.762a2.229,2.229,0,0,0,2.845-.247l2.623-2.664a1.045,1.045,0,0,1,1.477,0h0l4.629,4.643a1.043,1.043,0,0,1,.006,1.465Z" transform="translate(4.524 4.438)" fill="#fff"></path></svg>
                  </div>
                  <div className="">
        <a href="#">
            <img width="38" height="38" src="https://appscrip.com/wp-content/uploads/2021/03/whatsapp.svg" className="attachment-thumbnail2"/>
        </a>
    </div>
                  <div className="">
        <a href="#">
            <img width="38" height="38" src="https://appscrip.com/wp-content/uploads/2021/03/skype.svg" className="attachment-thumbnail3" alt=""/>
        </a>
    </div>
          </div>
        </nav>
      </header>
    </div>
    <div className='container mx-auto py-12'>
    <section className="md:px-12 px-4">
    <div className="grid mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='hero-section-title'>Contact us</p>
    <h1 className="hero-section-heading hero-section-heading-contactus lg:mt-4 mt-2">
    Execution is a skill - and we’ve mastered it!
    </h1>
    <p className="hero-section-text hero-section-text-contactus lg:mt-4 mt-2">
    It takes a special team to understand your business -
and build the perfect product to grow it. Developing a
product aligning with your brand is what our skilled team
does best.
    </p>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/undraw_internet_on_the_go_re_vben.svg" alt="mockup" className="lg:ps-12 object-contain h-auto w-full" />
  </div>
</div>

</section>
    </div>
    </div>
    <div className="container mx-auto md:px-12 px-4 pt-12 mb-12">
        <div className="contact-us-section">
        <div className="grid grid-cols-12 contact-us-section-bg md:px-12 px-4 md:py-12 py-8">
        <div className='lg:col-span-6 col-span-12 absollute'>
  <div className=''>
    <h2 className='footer-pg1-heading heading-contact-footer'>
    Say hello to us!
    </h2>
    <div className="flex items-center lg:mt-12 mt-8">
        <img src="assets/call.svg"/>
        <p className="contact-footer-text ms-4">+92 318 7842650</p>
    </div>
    <div className="flex items-center lg:mt-8 mt-4">
        <img src="assets/sms.svg"/>
        <p className="contact-footer-text ms-4">arslan@gmail.com</p>
    </div>
    <div className="flex items-start lg:mt-8 mt-4">
  <img src="assets/location.svg" className="mt-1" alt="Location Icon"/>
  <p className="contact-footer-text ms-5 md:w-1/2">Lahore Johar Town C - II Block Opposite UMT, Plot 38-A</p>
</div>


    
  </div>
                </div>
                <div className='lg:col-span-6 col-span-12 relative lg:mt-0 mt-8 contactUsection-grid2'>
                  <div className='footer1-border footer1-border-contact'>
                    <div className='flex items-center pt-6'>
                        <p className='flex footer1-text3'>1 <img src='assets/footer1-icon-3.svg' className='ms-1'/></p>
                        <h3 className='footer1-heading2 ms-4'>How can we help you?*</h3>
                    </div>
                    <div className='footer1-tab1 footer1-tab2 ml-11 mt-6 flex items-center'>
                      <h6 className='footer1-box ms-1'>A</h6>
                      <h5 className='ps-3 footer1-box-heading footer1-box-heading-contact pt-1'>Product Strategy</h5>
                    </div>
                    <div className='footer1-tab1 footer1-tab2 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>B</h6>
                      <h5 className='ps-3 footer1-box-heading footer1-box-heading-contact pt-1'>UI/UX Design</h5>
                    </div>
                    <div className='footer1-tab1 footer1-tab2 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>C</h6>
                      <h5 className='ps-3 footer1-box-heading footer1-box-heading-contact pt-1'>Web Development</h5>
                    </div>
                    <div className='footer1-tab1 footer1-tab2 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>D</h6>
                      <h5 className='ps-3 footer1-box-heading footer1-box-heading-contact pt-1'>App Development</h5>
                    </div>
                    <div className='footer1-tab1 footer1-tab2 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>E</h6>
                      <h5 className='ps-3 footer1-box-heading footer1-box-heading-contact pt-1'>Enterprise Solutions</h5>
                    </div>
                    <div className='footer1-tab1 footer1-tab2 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>F</h6>
                      <h5 className='ps-3 footer1-box-heading footer1-box-heading-contact pt-1'>Other</h5>
                    </div>
                    <button className='ml-11 flex footer1-btn1 items-center mt-3'onClick={openModal}>GET STARTED<img src='assets/tick.svg'className='ms-2'/></button>
                    <div className='absolute footer-btn-position footer-contact-position'>
                      <button className='footer1-btn2' onClick={handleScroll}><img src='assets/footerUp.svg'/></button>
                      <button className='footer1-btn3' onClick={handleScroll1}><img src='assets/footerDown.svg'/></button>
                    </div>
                  </div>
                </div>
        </div>
        <div className="md:px-12 px-4 md:py-12 py-8">
            <h3 className="section1-contact-heading">Pakistan</h3>
            <p className="section1-contact-text">Lahore Johar Town C - II Block Opposite UMT, Plot  38-A</p>
        </div>
        </div>
    </div>
    <div class="fixed-container">
    <div class="icon-wrapper">
        <a href="#">
            <img width="35" height="34" src="https://appscrip.com/wp-content/uploads/2021/03/whatsapp.svg" className="attachment-thumbnail"/>
        </a>
    </div>
    <div class="quick-contact-slide-left">
        <h6 class="rotated-text p-0">
            <a href="#">Quick Contact</a>
        </h6>
    </div>
    <div class="icon-wrapper">
        <a href="#">
            <img width="35" height="35" src="https://appscrip.com/wp-content/uploads/2021/03/skype.svg" className="attachment-thumbnail1" alt=""/>
        </a>
    </div>
</div>
<footer className="w-full footer2-bg relative" ref={sectionRef1}>
    <div className="container mx-auto lg:px-12 px-4 pt-12 pb-16">
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 lg:grid-cols-5">
          <div>
                  <h2 className="mb-6">Company</h2>
                  <ul>
                      <li className="mb-3">
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/services">
                        Services
                        </NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/portfolio">Portfolio</NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/contactUs">Contact us</NavLink>
                      </li>
                      <li className="mb-3">
                          <a href="#">Terms and Conditions</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Services</h2>
                  <ul>
                      <li className="mb-3">
                          <a href="#">App dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Web dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Wordpress dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Saas dev</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Process</h2>
                  <ul>
                      <li className="mb-3">
                          <a href="#">Design</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Ideate</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Build</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Grow</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Technologies</h2>
                  <ul className="">
                      <li className="mb-3">
                          <a href="#">Swift UI</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Wordpress</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">React JS</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">React native</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Connect</h2>
                  <ul>
                      <li class="mb-3">
                          <a href="#" className="footer2-li">+92 318 7842650</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">arslan@gmail.com</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Lahore Johar Town C - II Block Opposite UMT, Plot  38-A</a>
                      </li>
                      <li className="mb-3">
                      <a href="#" className="footer2-li">
  Location:   <span className="underline">Lahore</span>  |   <span className="underline">Pakistan</span>
</a>
                      </li>
                  </ul>
              </div>
          </div>
      <div className="sm:flex sm:items-center sm:justify-between mt-12">
          <span className="footer2-text">© 2024 <span className='footer2-span'>AppSavvy Labs</span> All Rights Reserved | Map | Policy
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
              <a href="#" className="footer2-social">
<img src='assets/footer2-img1.svg'/>
              </a>
              <a href="#" className="footer2-social ms-5">
              <img src='assets/footer2-img2.svg'/>
              </a>
              <a href="#" className="footer2-social ms-5">
                <img src='assets/footer2-img3.svg'/>
              </a>
          </div>
      </div>
    </div>
    <img src='assets/moon.svg' className='absolute right-0 footer2-position1'/>
    <img src='assets/footer-star1.svg' className='absolute footer2-position2'/>
</footer>
{isModalOpen && (
<div id="crud-modal" tabindex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" onClick={handleOverlayClick}>
    <div className="relative p-4 w-full max-w-md max-h-full mx-auto modal-width">
        <div className="modal-color relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                <button type="button" className="m-2 text-black bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal" onClick={closeModal}>
                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <div className="flex items-center justify-center md:pt-5 ps-4 pe-4 md:ps-5 md:pe-5 rounded-t dark:border-gray-600">
                <h3 className="modal-heading text-lg font-semibold text-gray-900 dark:text-white">
                    Contact Us
                </h3>
                <hr class="form-hr ms-4"/>
                </div>
                <div className='ps-4 pe-4 md:ps-5 md:pe-5 md:pt-5 pt-4'>
                  <h1 className='modal-heading-main'>See The Prodouct In Action</h1>
                  <p className='modal-text11 md:pt-3'>We'll get in touch with a FREE DEMO soon!</p>
                </div>
            <form ref={form} onSubmit={sendEmail} className="p-4 md:p-5">
                <div className="grid gap-4 mb-4 grid-cols-2">
                    <div className="col-span-2 md:pt-5">
                    <input type="text" name="user_name" id="floating_first_name" class="block py-2.5 pl-4 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none dark:text-white dark:border-black-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 peer" placeholder="Name" required />
                    </div>
                    <div className="col-span-2 md:pt-5">
                    <input type="email" name="user_email" id="floating_email" class="block py-2.5 pl-4 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 " placeholder="Email" required />
                    </div>
                    <div className="col-span-2 md:pt-5">
                    <textarea name='message' class="w-full pl-4 text-gray-700 bg-white modal-custom-border e dark:border-black-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0" placeholder="What is your project about?"></textarea>
                    </div>
                </div>
                <button type="submit" className="modal-btn text-white inline-flex items-center px-5 py-2.5">
                    SUBMIT
                </button>
            </form>
        </div>
    </div>
</div> 
)}
</div>
  );
}

export default ContactUs;
