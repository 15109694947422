import React from "react";
import "./App.css";
import HomePage from "./pages/homePage";
import Services from "./pages/services";
import Portfolio from "./pages/portfolio";
import ContactUs from "./pages/contactUs";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contactUs" element={<ContactUs />} />
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;

