import React, { useState,useRef} from 'react';
import '../css/homePage.css'
import { Navigation,Autoplay} from 'swiper/modules';
import { NavLink ,useNavigate, } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import { EffectCards } from 'swiper/modules';
import emailjs from '@emailjs/browser';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
const HomePage = () => {
  const form = useRef()
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex1, setActiveIndex1] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sectionRef = useRef(null);
  const sectionRef1 = useRef(null);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ci7uqmj', 'template_6zi5h0i', form.current, {
        publicKey: 'kx-y9KhqWZgdIxqxH',
      })
      .then(
        () => {
          closeModal();
          toastr.success("Email sent successfully");
        },
        (error) => {
          toastr.error(error);
        },
      );
  };

  const handleScroll = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScroll1 = () => {
    if (sectionRef1.current) {
      sectionRef1.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleOverlayClick = (event) => {
    if (event.target.id === 'crud-modal') {
      closeModal();
    }
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleClick = (index) => {
    setActiveIndex(index);
  };
  const handleClick1 = (index) => {
    setActiveIndex1(index);
  };
  const handleNavigate =()=>{
    navigate('/portfolio');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div>
    <div className='w-full header-section'>
    <div className="container mx-auto">
      <header className='pt-8 pb-8 md:px-12 px-4'>
      <nav className="navbar-1 bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800 sticky top-0 z-50">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <a className="flex items-center navbar-heading" href='#'>
            App Dev &nbsp;<span className='navbar-heading-span'>Master.</span>
            </a>
            <div className="flex items-center lg:order-2">
              <button
                onClick={toggleMenu}
                type="button"
                className="toggler-btn inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded={isOpen ? "true" : "false"}
              >
                <span className="sr-only">Open main menu</span>
                <svg className={`w-6 h-6 ${isOpen ? "hidden" : "block"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                </svg>
                <svg className={`w-6 h-6 ${isOpen ? "block" : "hidden"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </button>
            </div>
            <div className={`w-full lg:flex lg:w-auto lg:order-2 ${isOpen ? "block" : "hidden"}`} id="mobile-menu-2">
              <ul className="flex navbar-custom-flex mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <a href="#" className="block py-2 pr-4 pl-3">Home</a>
                </li>
                <li>
                <NavLink className="block py-2 pr-4 pl-3" to="/services">
                Services
          </NavLink>
                </li>
                <li>
                <NavLink className="block py-2 pr-4 pl-3" to="/portfolio">
                Portfolio
                  </NavLink>
                </li>
                <li className='flex justify-center lg:pb-0 pb-2 navbar-phone'>
                  <a href='#'>
                  <svg xmlns="http://www.w3.org/2000/svg" id="Component_56_1" data-name="Component 56 – 1" width="38" height="38" viewBox="0 0 54 54"><rect id="Rectangle_24465" data-name="Rectangle 24465" width="54" height="54" rx="27" fill="#8748C2"></rect><path id="Path_125981" data-name="Path 125981" d="M34.98,31.956h0v-.009l-1.249,1.242a6.577,6.577,0,0,1-6.217,1.785,21.856,21.856,0,0,1-6.342-2.84A29.327,29.327,0,0,1,16.164,28.1a29.552,29.552,0,0,1-3.741-4.544,23.02,23.02,0,0,1-2.812-5.689,6.577,6.577,0,0,1,1.644-6.718l1.458-1.464a1.045,1.045,0,0,1,1.477,0h0L18.812,14.3a1.045,1.045,0,0,1,0,1.477h0L16.1,18.494a2.211,2.211,0,0,0-.231,2.877,31.732,31.732,0,0,0,3.256,3.8A31.538,31.538,0,0,0,23.4,28.762a2.229,2.229,0,0,0,2.845-.247l2.623-2.664a1.045,1.045,0,0,1,1.477,0h0l4.629,4.643a1.043,1.043,0,0,1,.006,1.465Z" transform="translate(4.524 4.438)" fill="#fff"></path></svg>
                  </a>
                  </li>
                <li>
                <NavLink className="navbar-button block py-2 pr-4 pl-3" to="/contactUs">
                Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className='navbar-custom-center'>
              <div>
                  <svg xmlns="http://www.w3.org/2000/svg" id="Component_56_1" data-name="Component 56 – 1" width="38" height="38" viewBox="0 0 54 54"><rect id="Rectangle_24465" data-name="Rectangle 24465" width="54" height="54" rx="27" fill="#8748C2"></rect><path id="Path_125981" data-name="Path 125981" d="M34.98,31.956h0v-.009l-1.249,1.242a6.577,6.577,0,0,1-6.217,1.785,21.856,21.856,0,0,1-6.342-2.84A29.327,29.327,0,0,1,16.164,28.1a29.552,29.552,0,0,1-3.741-4.544,23.02,23.02,0,0,1-2.812-5.689,6.577,6.577,0,0,1,1.644-6.718l1.458-1.464a1.045,1.045,0,0,1,1.477,0h0L18.812,14.3a1.045,1.045,0,0,1,0,1.477h0L16.1,18.494a2.211,2.211,0,0,0-.231,2.877,31.732,31.732,0,0,0,3.256,3.8A31.538,31.538,0,0,0,23.4,28.762a2.229,2.229,0,0,0,2.845-.247l2.623-2.664a1.045,1.045,0,0,1,1.477,0h0l4.629,4.643a1.043,1.043,0,0,1,.006,1.465Z" transform="translate(4.524 4.438)" fill="#fff"></path></svg>
                  </div>
                  <div className="">
        <a href="#">
            <img width="38" height="38" src="https://appscrip.com/wp-content/uploads/2021/03/whatsapp.svg" className="attachment-thumbnail2"/>
        </a>
    </div>
                  <div className="">
        <a href="#">
            <img width="38" height="38" src="https://appscrip.com/wp-content/uploads/2021/03/skype.svg" className="attachment-thumbnail3" alt=""/>
        </a>
    </div>
          </div>
          
        </nav>
      </header>
    </div>
    <div className='container mx-auto py-12'>
    <section className="md:px-12 px-4">
    <div className="grid mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='hero-section-title'>PRODUCT, DESIGN AND DEVELOPMENT</p>
    <h1 className="hero-section-heading lg:mt-4 mt-2">
    Your product partner for tackling the digital frontier
    </h1>
    <p className="hero-section-text lg:mt-4 mt-2">
    This company will combines strategy, product, design, and engineering to build revolutionary digital products and experiences.
    </p>
    <button className="hero-section-btn flex items-center lg:mt-4 mt-2"  onClick={openModal}>
      GET STARTED<img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/>
    </button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/homeHeroimg.png" alt="mockup" className="object-contain h-auto w-full" />
  </div>
</div>

</section>
    </div>
    </div>
    <div class="fixed-container">
    <div class="icon-wrapper">
        <a href="#">
            <img width="35" height="34" src="https://appscrip.com/wp-content/uploads/2021/03/whatsapp.svg" className="attachment-thumbnail"/>
        </a>
    </div>
    <div class="quick-contact-slide-left">
        <h6 class="rotated-text p-0">
            <a href="#">Quick Contact</a>
        </h6>
    </div>
    <div class="icon-wrapper">
        <a href="#">
            <img width="35" height="35" src="https://appscrip.com/wp-content/uploads/2021/03/skype.svg" className="attachment-thumbnail1" alt=""/>
        </a>
    </div>
</div>
    <div className='w-full bg-pg1-s2'>
    <div className='container mx-auto'>
    <div class="flex flex-wrap md:px-20 py-12 gap-4 px-4 justify-center lg:justify-between lg:flex-nowrap">
  <img src="assets/img1pg1.svg" alt="Image 1" class="image-custom-s2"/>
  <img src="assets/img2pg1.svg" alt="Image 2" class="image-custom-s2"/>
  <img src="assets/img3pg1.svg" alt="Image 3" class="image-custom-s2"/>
  <img src="assets/img4pg1.svg" alt="Image 4" class="image-custom-s2"/>
  <img src="assets/img5pg1.svg" alt="Image 5" class="image1-custom-s2"/>
</div>


      </div>
      </div>
    
      <div className='w-full relative'>
      <div className='container mx-auto'>
        
        <div className='grid flex mx-auto md:px-12 pt-12 px-4  lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1'>
        <div className='lg:col-span-12'>
        <p className='s3-pg1-title'>WHO WE ARE</p>
        </div>
        <div className="lg:col-span-6 col-span-12 mr-8">
          <h2 className='s3-pg1-heading'>Building digital products from scratch</h2>
          </div>
          <div className="lg:col-span-6 col-span-12">
          <p className='s3-pg1-text lg:mt-2'>In our efforts, we treat every software idea - a new launch or a modernized legacy solution - equally. We follow a digital product design approach that uses complex technologies to build creative solutions from square one of the product life cycle.</p>
          </div>
        </div>
      </div>
      <img src='assets/moon.svg' className='moon-pg1-positon'/>
      </div>
      <div className='container mx-auto'>
<div className='grid flex gap-8 mx-auto md:px-32 pt-12 md:grid-cols-12 grid-cols-12'>
  <div className='lg:col-span-4 md:col-span-6 col-span-8 md:col-start-0 col-start-3'>
    <div className='s4-pg1-card1 px-4 py-8'>
    <img src='assets/s4-image2.svg' className='s4-pg1-img'/>
    <h3 className='s4-pg1-heading'>Assured proficiency</h3>
    <p className='s4-pg1-text'>Our interdisciplinary team brings Silicon Valley design and development practices to the rest of the world</p>
    </div>
  </div>
  <div className='lg:col-span-4 md:col-span-6 col-span-8 md:col-start-0  col-start-3'>
  <div className='s4-pg1-card1 s4-pg1-card2 px-4 py-8'>
    <img src='assets/s4-image3.svg' className='s4-pg1-img'/>
    <h3 className='s4-pg1-heading'>Swift rollout</h3>
    <p className='s4-pg1-text'>We ship on time, without compromising on user-experience or quality.</p>
    </div>
  </div>
  <div className='lg:col-span-4 md:col-span-6 col-span-8 md:col-start-4 lg-col-start-0 col-start-3'>
  <div className='s4-pg1-card1 px-4 py-8'>
    <img src='assets/s4-image4.svg' className='s4-pg1-img'/>
    <h3 className='s4-pg1-heading'>You-focused</h3>
    <p className='s4-pg1-text'>You are a partner, not a client. We work together to build revolutionary products.</p>
    </div>
  </div>
</div>
      </div>
      <div className='w-full relative s5-pg1-bg mt-12'>
        <div className='container mx-auto'>
          <div className='grid md:px-12 px-4 pt-12 flex grid-cols-12'>
          <div className='col-span-12'>
          <h6 className='s3-pg1-title'>OUR SERVICES</h6>
            </div>
            <div className='lg:col-span-6 col-span-12'>
              <div>
                <h2 className='s3-pg1-heading'>Discover how we build revolutionary products</h2>
              </div>
            </div>
            <div className='lg:col-span-6 col-span-12'>
<p className='s3-pg1-text mt-2'>Company helps enterprises and startups take their product ideas from 0 to 100. Our team of ex-Silicon Valley experts help companies from at all stages build cutting edge solutions through product strategy, design and revolutionary engineering.</p>
<a className='s5-pg1-btn flex items-center mt-2'  onClick={openModal}>GET STARTED <img src='assets/s5-arrow.svg' className='ms-4'/></a>
</div>
          </div>
          <div className='grid md:px-32 pt-12 px-4 pb-12 flex items-center md:grid-cols-12'>
          <div className="lg:col-span-6 col-span-12 ideate-section-pg1 grid lg:grid-cols-1 grid-cols-2 gap-4">
        <div className={`flex relative items-center ${activeIndex === 0 ? 'active' : ''}`}>
          <h4 className='ms-4 s7-pg1-number s7-pg1-number1'>01.</h4>
          <h4 className='ms-4 s7-pg1-heading1 s7-pg1-heading2 cursor-pointer' onClick={() => handleClick(0)}>Ideate</h4>
          <img src='assets/s5-check.svg' className={`absolute s6-pg1-position ${activeIndex === 0 ? 'block' : 'hidden'}`} />
        </div>
        <div className={`flex relative items-center ${activeIndex === 1 ? 'active' : ''}`}>
          <h4 className='ms-4 s7-pg1-number'>02.</h4>
          <h4 className='ms-4 s7-pg1-heading1 cursor-pointer' onClick={() => handleClick(1)}>Design</h4>
          <img src='assets/s5-check.svg' className={`absolute s6-pg1-position ${activeIndex === 1 ? 'block' : 'hidden'}`} />
        </div>
        <div className={`flex relative items-center ${activeIndex === 2 ? 'active' : ''}`}>
          <h4 className='ms-4 s7-pg1-number'>03.</h4>
          <h4 className='ms-4 s7-pg1-heading1 cursor-pointer' onClick={() => handleClick(2)}>Build</h4>
          <img src='assets/s5-check.svg' className={`absolute s6-pg1-position ${activeIndex === 2 ? 'block' : 'hidden'}`} />
        </div>
        <div className={`flex relative items-center ${activeIndex === 3 ? 'active' : ''}`}>
          <h4 className='ms-4 s7-pg1-number'>04.</h4>
          <h4 className='ms-4 s7-pg1-heading1 cursor-pointer' onClick={() => handleClick(3)}>Grow</h4>
          <img src='assets/s5-check.svg' className={`absolute s6-pg1-position ${activeIndex === 3 ? 'block' : 'hidden'}`} />
        </div>
      </div>
            <div className='lg:col-span-6 col-span-12 lg:mt-0 mt-8'>
        <img src='assets/s5-main-img.png' className={`object-contain h-auto w-full ${activeIndex === 0 ? 'block' : 'hidden'}`} />
        <img src='assets/s5-main-img1.png' className={`object-contain h-auto w-full ${activeIndex === 1 ? 'block' : 'hidden'}`} />
        <img src='assets/s5-main-img2.png' className={`object-contain h-auto w-full ${activeIndex === 2 ? 'block' : 'hidden'}`} />
        <img src='assets/s5-main-img3.png' className={`object-contain h-auto w-full ${activeIndex === 3 ? 'block' : 'hidden'}`} />
      </div>
          </div>
        </div>
        <img src='assets/Comet.svg' className='absolute comet-position-pg1-s7'/>
      </div>
      <div className='container mx-auto'>
        <div className='grid  md:px-12 px-4 pt-12 grid-cols-12'>
          <div className='lg:col-span-10 col-span-12'>
            <h2 className='s3-pg1-heading1'>Converting your idea into a pillar of the startup community</h2>
          </div>
        </div>
      </div>
<div className='w-full mx-auto'>
  <div className=' gap-4 flex flex-wrap justify-center px-4 pt-12 md:px-12'>
      <div className='flex items-center s8-border-pg1'>
        <img src='assets/s8-img1.svg'/>
        <h5 className='s8-heading-pg1 md:ms-4 ms-2'>Web Development</h5>
      </div>
      <div className='flex items-center s8-border-pg1'>
        <img src='assets/s8-img2.svg'/>
        <h5 className='s8-heading-pg1 md:ms-4 ms-2'>UX/UI</h5>
      </div>
      <div className='flex items-center s8-border-pg1'>
        <img src='assets/s8-img3.svg'/>
        <h5 className='s8-heading-pg1 md:ms-4 ms-2'>App Development</h5>
      </div>
      <div className='flex items-center s8-border-pg1'>
        <img src='assets/s8-img4.svg'/>
        <h5 className='s8-heading-pg1 md:ms-4 ms-2'>Staff Augmentation</h5>
      </div>
      <div className='flex items-center s8-border-pg1'>
        <img src='assets/s8-img5.svg'/>
        <h5 className='s8-heading-pg1 md:ms-4 ms-2'>Blockchain</h5>
      </div>
      <div className='flex items-center s8-border-pg1'>
        <img src='assets/s8-img6.svg'/>
        <h5 className='s8-heading-pg1 md:ms-4 ms-2'>Machine Learning</h5>
      </div>
  </div>
</div>
<div className='container mx-auto'>
  <div className='md:px-12 px-4 pt-12'>
        <div className='grid relative grid-cols-12 md:px-12 px-4 s9-bg-pg1 pb-12'>
          <div className='lg:col-span-6 col-span-12 mt-16 '>
            <h2 className='s9-heading-pg1'>Converting your idea into a reality of the startup.</h2>
          </div>
          <div className='lg:col-span-6 col-span-12 lg:mt-16 mt-0 relative'>
            <p className='s9-text-pg1 lg:mt-2'>At company , we build your digital product idea from the ground up and
make it an investors’ favorite. A journey that starts and is embedded with
emerging technologies.</p>
<img src='assets/idea-icon-star.svg' className='absolute custom-position1-s9-pg1'/>
          </div>
          <div className='col-span-12 flex flex-wrap justify-center mt-8 s9-custom-gap-pg1'>
  <div className='flex items-center s9-border-pg1 md:mt-4 mt-2'>
    <img src='assets/s9-pg1-img1.svg'/>
    <div>
      <h5 className='s9-heading2-pg1 md:ms-3 ms-2'>Mobile App</h5>
      <h5 className='s9-heading2-pg1 md:ms-3 ms-2'>Development</h5>
    </div>
  </div>
  <div className='flex items-center s9-border-pg1 md:mt-4 mt-2'>
    <img src='assets/s9-pg1-img2.svg'/>
    <div>
      <h5 className='s9-heading2-pg1 md:ms-3 ms-2'>Web App</h5>
      <h5 className='s9-heading2-pg1 md:ms-3 ms-2'>Development</h5>
    </div>
  </div>
  <div className='flex items-center s9-border-pg1 md:mt-4 mt-2'>
    <img src='assets/s9-pg1-img3.svg'/>
    <div>
      <h5 className='s9-heading2-pg1 md:ms-3 ms-2'>SaaS App</h5>
      <h5 className='s9-heading2-pg1 md:ms-3 ms-2'>Development</h5>
    </div>
  </div>
  <div className='flex items-center s9-border-pg1 md:mt-4 mt-2'>
    <img src='assets/s9-pg1-img4.svg'/>
    <div>
      <h5 className='s9-heading2-pg1 md:ms-3 ms-2'>Cloud</h5>
      <h5 className='s9-heading2-pg1 md:ms-3 ms-2'>Configurations</h5>
    </div>
  </div>
</div>
<img src='assets/half-moon-left.png' className='absolute custom-position-s9-pg1'/>
        </div>
        </div>
      </div>
      <div className='w-full s10-bg-pg1'>
        <div className='container mx-auto md:px-12 px-4'>
        <div className='grid relative grid-cols-12 pt-16 pb-16'>
          <div className='lg:col-span-5 col-span-12'>
            <h2 className='s10-heading-pg1'>Offering you cutting-edge technologies</h2>
            <p className='s10-text-pg1'>We have worked with all the technology combinations so that you don’t have to.</p>
          </div>
          <div className='lg:col-span-7 col-span-12 lg:mt-0 mt-4 positon-s10-pg1'>
            <div className='positon1-s10-pg1'>
            <img src='assets/tech-img.png'/>
            </div>
          </div>
          <div className='lg:col-span-5 col-span-12 pt-12 lg:grid-cols-1 md:grid-cols-2 gap-4'>
          <div className='flex items-center'>
        <img src={activeIndex1 === 0 ? 'assets/Background+Border.svg' : 'assets/Border.svg'} />
        <h5 className={`s10-text3-pg1 ms-4 ${activeIndex1 === 0 ? 'activeTechnology' : ''}`} onClick={() => handleClick1(0)}>Technologies</h5>
      </div>
      <div className='flex items-center'>
        <img src={activeIndex1 === 1 ? 'assets/Background+Border.svg' : 'assets/Border.svg'} />
        <h5 className={`s10-text3-pg1 ms-4 ${activeIndex1 === 1 ? 'activeTechnology' : ''}`} onClick={() => handleClick1(1)}>Cloud Services</h5>
      </div>
      <div className='flex items-center'>
        <img src={activeIndex1 === 2 ? 'assets/Background+Border.svg' : 'assets/Border.svg'} />
        <h5 className={`s10-text3-pg1 ms-4 ${activeIndex1 === 2 ? 'activeTechnology' : ''}`} onClick={() => handleClick1(2)}>AI Integrations</h5>
      </div>
      <div className='flex items-center'>
        <img src={activeIndex1 === 3 ? 'assets/Background+Border.svg' : 'assets/Border.svg'} />
        <h5 className={`s10-text3-pg1 ms-4 ${activeIndex1 === 3 ? 'activeTechnology' : ''}`} onClick={() => handleClick1(3)}>Integrations</h5>
      </div>
      <div className='flex items-center'>
        <img src={activeIndex1 === 4 ? 'assets/Background+Border.svg' : 'assets/Border.svg'} />
        <h5 className={`s10-text3-pg1 ms-4 ${activeIndex1 === 4 ? 'activeTechnology' : ''}`} onClick={() => handleClick1(4)}>Tools</h5>
      </div>
</div>

          <div className='lg:col-span-7 col-span-12 pt-12'>
          {activeIndex1 == 0 && (
   <div className='flex justify-center lg:justify-start flex-wrap gap-4'>
    <div className='s10-card-pg1'>
        <img src='assets/react.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img1.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img2.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img3.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img4.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img5.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img6.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img7.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img8.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img9.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img10.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img11.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img12.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img13.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img14.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img15.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img16.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img17.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img18.svg'/>
    </div>
    </div>
          )}
           {activeIndex1 == 1 && (
    <div className='flex justify-center lg:justify-start flex-wrap gap-4'>    
    <div className='s10-card-pg1'>
    <img src='assets/s10-img2.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img3.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img2.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img3.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img2.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img3.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img4.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img5.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img6.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img7.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img8.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img9.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img10.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img11.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img12.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img13.svg'/>
</div>
<div className='s10-card-pg1'>
    <img src='assets/s10-img14.svg'/>
</div>
</div>
    )}
    {activeIndex1 == 2 && (
        <div className='flex justify-center lg:justify-start flex-wrap gap-4'>    
        <div className='s10-card-pg1'>
        <img src='assets/s10-img2.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img3.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img2.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img3.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img2.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img3.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img4.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img5.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img6.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img7.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img8.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img9.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img10.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img11.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img12.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img13.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img14.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img12.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img13.svg'/>
    </div>
    <div className='s10-card-pg1'>
        <img src='assets/s10-img14.svg'/>
    </div>
    </div>
    )}
    {activeIndex1 == 3 && (
            <div className='flex justify-center lg:justify-start flex-wrap gap-4'>    
            <div className='s10-card-pg1'>
            <img src='assets/s10-img2.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img3.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img2.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img3.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img2.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img3.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img4.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img5.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img6.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img7.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img8.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img9.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img10.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img11.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img12.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img13.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img14.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img12.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img13.svg'/>
        </div>
        </div>
    )}
    {activeIndex1 == 4 && (
            <div className='flex justify-center lg:justify-start flex-wrap gap-4'>    
            <div className='s10-card-pg1'>
            <img src='assets/s10-img2.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img3.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img2.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img3.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img2.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img3.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img4.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img5.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img6.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img7.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img8.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img9.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img10.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img11.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img12.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img13.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img14.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img12.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img13.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img14.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img13.svg'/>
        </div>
        <div className='s10-card-pg1'>
            <img src='assets/s10-img14.svg'/>
        </div>
        </div>
    )}
</div>
<img src='assets/VerticalDivider.svg' className='absolute bottom-0 left-2 verticalDivider'/>
          </div>
        </div>
      </div>
      <div className='w-full relative'>
      <div className='container mx-auto'>
        
        <div className='grid mx-auto md:px-12 pt-12 px-4 lg:grid-cols-12 grid-cols-1'>
        <div className="lg:col-span-6 col-span-12 mr-8">
          <h2 className='s3-pg1-heading'>The best in class for a reason</h2>
          </div>
          <div className="lg:col-span-6 col-span-12">
          <p className='s3-pg1-text lg:mt-2 s11-pg1-width'>Our commitment to excellence and innovation has helped us become the leading digital transformation company in world.</p>
          </div>
        </div>
      </div>
      <div className='absolute s11-position-pg1'>
        <img src='assets/rocket2.svg'/>
        </div>
      </div>
      <div className='w-full mx-auto lg:mt-24 mt-12'>
        
        <div className='container mx-auto md:ps-12 ps-4'>
      <Swiper
       modules={[Autoplay]}
       autoplay={{
         delay: 2000,
         disableOnInteraction: false,
       }}
      spaceBetween={20}
      breakpoints={{
        320: {
          slidesPerView: 1.1,
        },
        500:{
          slidesPerView:1.5,
        },
        768: {
          slidesPerView: 2,
        },
        1023: {
          slidesPerView: 2.5,
        },
        1279: {
          slidesPerView: 3.3,
        },
      }}
    >
      <SwiperSlide className=''>
        <div className='card-s12-pg1'>
          <img src='assets/logo.svg'/>
          <p className="card-s12-pg1-title mt-3">HONORS</p>
          <h3 className="card-s12-pg1-heading mt-8">Top<br />Website<br />Developers</h3>
          <img src='assets/clutch.svg' className='mt-16'/>
          <p className="card-s12-pg1-text">2024</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className=''>
        <div className='card-s12-pg1'>
          <img src='assets/logo.svg'/>
          <p className="card-s12-pg1-title mt-3">HONORS</p>
          <h3 className="card-s12-pg1-heading mt-8">Top<br />App<br />Developers</h3>
          <img src='assets/clutch.svg' className='mt-16'/>
          <p className="card-s12-pg1-text">2024</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className=''>
        <div className='card-s12-pg1'>
          <img src='assets/logo.svg'/>
          <p className="card-s12-pg1-title mt-3">HONORS</p>
          <h3 className="card-s12-pg1-heading mt-8">Web<br />Excellence<br />Awards</h3>
          <img src='assets/clutch2.svg' className='mt-16'/>
          <p className="card-s12-pg1-text">2023</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className=''>
        <div className='card-s12-pg1'>
          <img src='assets/logo.svg'/>
          <p className="card-s12-pg1-title mt-3">HONORS</p>
          <h3 className="card-s12-pg1-heading mt-8">Best<br />AI/ML<br />Integrators</h3>
          <img src='assets/clutch3.svg' className='mt-16'/>
          <p className="card-s12-pg1-text">2023</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className=''>
        <div className='card-s12-pg1'>
          <img src='assets/logo.svg'/>
          <p className="card-s12-pg1-title mt-3">HONORS</p>
          <h3 className="card-s12-pg1-heading mt-8">Level 3<br />Certified<br />Organization</h3>
          <img src='assets/cmmi-small.svg' className='mt-16'/>
          <p className="card-s12-pg1-text">2023</p>
        </div>
      </SwiperSlide>
    </Swiper>
          </div>
          </div>
          <div className='container mx-auto'>
          <div className='grid md:px-12 px-4 pt-12 flex grid-cols-12'>
          <div className='col-span-12'>
          <h6 className='s3-pg1-title'>OUR WORK</h6>
            </div>
            <div className='lg:col-span-6 col-span-12'>
              <div>
                <h2 className='s3-pg1-heading'>Crafted with love. Built with passion</h2>
              </div>
            </div>
            <div className='lg:col-span-6 col-span-12'>
<p className='s3-pg1-text mt-2'>We love dreamers. We love people with big ideas. Most of all, we love bringing those dreams and ideas to life.</p>
<a className='s5-pg1-btn flex items-center mt-2'>KNOW MORE <img src='assets/s5-arrow.svg' className='ms-4'/></a>
</div>
          </div>
          </div>
          <div className='container mx-auto'>
          <div className='grid md:px-12 flex gap-4 px-4 pt-12 grid-cols-12'>
            <div className='lg:col-span-5 col-span-12'>
              <div className='s13-pg1-bg relative'>
              <h6 className='s13-pg1-title'>DOUGHFI</h6>
                <h2 className='s13-pg1-heading'>Crafting an App that redefines merchant payments</h2>
              <div className='absolute bottom-0 position-img1-s13'>
                <img src='assets/doughfi.png' className='object-contain h-auto w-full pt-12'/>
              </div>
              </div>
            </div>
            <div className='lg:col-span-7 col-span-12'>
              <div className='s13-pg1-bg1'>
                <div className='custom-padding-pg1-s13'>
                <div className='s13-pg1-inner-bg1'>
                  <h6 className='inner-s13-pg1-title'>BRIEF</h6>
                  <p className='inner-s13-pg1-text'>DoughFi and us revolutionized high-risk business banking with a Stellar Network blockchain platform, featuring P2P merchant transfers, QR code lookups, and live chat support, simplifying transactions and enhancing user satisfaction.</p>
                </div>
                <div className='custom-padding1-pg1-s13'>
                  <h5 className='inner-s13-pg1-title1 mt-8'>TECH STACK</h5>
                  <div className='flex mt-4'>
                    <img src='assets/s13-img-1.svg' className='inner-img-s13'/>
                    <img src='assets/s13-img-2.svg' className='inner-img-s13 ms-2'/>
                    <img src='assets/s13-img-3.svg' className='inner-img-s13 ms-2'/>
                    <img src='assets/s13-img-4.svg' className='inner-img-s13 ms-2'/>
                  </div>
                  <div className='flex flex-wrap gap-2 mt-12'>
                    <div className='ronded-box-s13-pg1'>
                      <p className='rounded-box-text-s13'>FINTECH</p>
                    </div>
                    <div className='ronded-box-s13-pg1'>
                      <p className='rounded-box-text-s13'>BLOCKCHAIN</p>
                    </div>
                    <div className='ronded-box-s13-pg1'>
                      <p className='rounded-box-text-s13'>DESIGN</p>
                    </div>
                    <div className='ronded-box-s13-pg1'>
                      <p className='rounded-box-text-s13'>APP DEVELOPMENT</p>
                    </div>
                  </div>
                </div>
                </div>
              </div>
</div>
<div className='lg:col-span-5 col-span-12 mt-4'>
              <div className='s13-pg1-bg s13-pg1-bg3 relative'>
              <h6 className='s13-pg1-title s13-pg1-title1'>OPAR</h6>
                <h2 className='s13-pg1-heading s13-pg1-heading1'>Helping connect same-vibe people with the trendy spots in their city.</h2>
              <div className='flex justify-center absolute bottom-0 position-img1-s13 custom-padding5-s13'>
                <img src='assets/opar.png' className='object-contain h-2/3 pt-12'/>
              </div>
              </div>
            </div>
            <div className='lg:col-span-7 col-span-12 mt-4'>
              <div className='s13-pg1-bg1 s13-pg1-bg4'>
                <div className='custom-padding-pg1-s13'>
                <div className='s13-pg1-inner-bg1 s13-pg1-inner-bg2'>
                  <h6 className='inner-s13-pg1-title inner-s13-pg1-title1'>BRIEF</h6>
                  <p className='inner-s13-pg1-text inner-s13-pg1-text1'>We helped Opar tackle social disconnect by implementing in-
app messaging for venue-specific & open user interaction,
crafting games based on user engagement points, and
integrating a social media interface for sharing venue
experiences and fostering connection.</p>
                </div>
                <div className='custom-padding1-pg1-s13'>
                  <h5 className='inner-s13-pg1-title1 mt-8'>TECH STACK</h5>
                  <div className='flex mt-4'>
                    <img src='assets/s13-img-1.svg' className='inner-img-s13'/>
                    <img src='assets/s13-img-2.svg' className='inner-img-s13 ms-2'/>
                    <img src='assets/s13-img-3.svg' className='inner-img-s13 ms-2'/>
                    <img src='assets/s13-img-4.svg' className='inner-img-s13 ms-2'/>
                  </div>
                  <div className='flex flex-wrap gap-2 mt-12'>
                    <div className='ronded-box-s13-pg1 rounded-box1-s13-pg1'>
                      <p className='rounded-box-text-s13'>SOCIAL MEDIA</p>
                    </div>
                    <div className='ronded-box-s13-pg1 rounded-box1-s13-pg1'>
                      <p className='rounded-box-text-s13 rounded-box-text1-s13'>DESIGN</p>
                    </div>
                    <div className='ronded-box-s13-pg1 rounded-box1-s13-pg1'>
                      <p className='rounded-box-text-s13 rounded-box-text1-s13'>App Development</p>
                    </div>
                  </div>
                </div>
                </div>
              </div>
</div>
<div className='lg:col-span-5 col-span-12 mt-4'>
              <div className='s13-pg1-bg s13-pg1-bg5 relative'>
              <h6 className='s13-pg1-title s13-pg1-title3 '>CARISTAS</h6>
                <h2 className='s13-pg1-heading s13-pg1-heading3'>Building an intuitive car buying platform</h2>
              <div className='flex justify-center absolute bottom-0 position-img1-s13 custom-padding6-s13'>
                <img src='assets/caristas.png' className='object-contain h-auto w-full pt-12'/>
              </div>
              </div>
            </div>
            <div className='lg:col-span-7 col-span-12 mt-4'>
              <div className='s13-pg1-bg1 s13-pg1-bg8'>
                <div className='custom-padding-pg1-s13'>
                <div className='s13-pg1-inner-bg1 s13-pg1-inner-bg9'>
                  <h6 className='inner-s13-pg1-title inner-s13-pg1-title1'>BRIEF</h6>
                  <p className='inner-s13-pg1-text inner-s13-pg1-text1'>We helped Caristas simplify online car buying, which often overwhelms users with too many options and limited information.</p>
                </div>
                <div className='custom-padding1-pg1-s13'>
                  <h5 className='inner-s13-pg1-title1 mt-8'>TECH STACK</h5>
                  <div className='flex flex-wrap gap-2 mt-4'>
                  <img src='assets/s13-img-4.svg' className='inner-img-s13'/>
                  <img src='assets/s13-img-2.svg' className='inner-img-s13'/>
                  <img src='assets/s13-img-5.svg' className='inner-img-s13'/>
                    <img src='assets/s13-img-1.svg' className='inner-img-s13'/>

                    <img src='assets/s13-img-3.svg' className='inner-img-s13'/>
                    <img src='assets/s13-img-6.svg' className='inner-img-s13'/>
                    <img src='assets/s13-img-7.svg' className='inner-img-s13'/>
                  </div>
                  <div className='flex flex-wrap gap-2 mt-12'>
                    <div className='ronded-box-s13-pg1 ronded-box6-s13-pg1'>
                      <p className='rounded-box-text-s13 rounded-box-text5-s13'>DESIGN</p>
                    </div>
                    <div className='ronded-box-s13-pg1 ronded-box6-s13-pg1'>
                      <p className='rounded-box-text-s13 rounded-box-text-s13'>AUTOMOBILE</p>
                    </div>
                    <div className='ronded-box-s13-pg1 ronded-box6-s13-pg1'>
                      <p className='rounded-box-text-s13 rounded-box-text-s13'>APP DEVELOPMENT</p>
                    </div>
                    <div className='ronded-box-s13-pg1 ronded-box6-s13-pg1'>
                      <p className='rounded-box-text-s13 rounded-box-text-s13'>BLOCKCHAIN</p>
                    </div>
                  </div>
                </div>
                </div>
              </div>
</div>
          </div>
          </div>
          <div className='w-full bg-img-s14 mt-12 overflow-hidden'>
            <div className='container mx-auto'>
            <div className='grid md:px-12 gap-4 px-4 lg:pt-16 md:pt-12 lg:mt-16 grid-cols-12'>
              <div className='lg:col-span-6 col-span-12'>
                <h2 className='s13-pg1-heading s14-heading-pg1 lg:mt-16 mt-8'>Real Stories, Real Results</h2>
                <p className='s3-pg1-text s14-text-pg1 mt-8'>Our partners trust us with their ideas and we reciprocate with a digital solution that becomes viral.</p>
              <button className='s14-btn1-pg1 flex items-center mt-8' onClick={handleNavigate}>View portfolio<img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
              </div>
              <div className='lg:col-span-6 col-span-12 lg:mt-0 mt-8 mb-8 home-slider'>
              <Swiper
                    modules={[Navigation,Autoplay,EffectCards]}
                    spaceBetween={20}
                    navigation
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
        effect={'cards'}
        grabCursor={true}
        className="mySwiper"
      >
        <SwiperSlide className='swiper-slide-2'>
          <div className='swiper-slide-2-card'>
            <img src='assets/real-comma.svg'/>
            <p className='swiper-slide-2-text mt-2'>Their agile development process allowed for efficient iterations and quick adjustments. Overall, it's been a fruitful collaboration. <span className='swiper-slide-2-heading'>I'm thrilled with company name work</span></p>
          <hr className='horiziontal-line-slide mt-8'/>
          <h4 className='swiper-slide-2-heading1 mt-2'>Matthew -Founder</h4>
          <img src='assets/beerrun-logo.svg'/>
          </div>
        </SwiperSlide>
        <SwiperSlide className='swiper-slide-2'>
          <div className='swiper-slide-2-card'>
            <img src='assets/real-comma.svg'/>
            <p className='swiper-slide-2-text mt-2'>Their agile development process allowed for efficient iterations and quick adjustments. Overall, it's been a fruitful collaboration. <span className='swiper-slide-2-heading'>I'm thrilled with company name work</span></p>
          <hr className='horiziontal-line-slide mt-8'/>
          <h4 className='swiper-slide-2-heading1 mt-2'>Matthew -Founder</h4>
          <img src='assets/beerrun-logo.svg'/>
          </div>
        </SwiperSlide>
        <SwiperSlide className='swiper-slide-2'>
          <div className='swiper-slide-2-card'>
            <img src='assets/real-comma.svg'/>
            <p className='swiper-slide-2-text mt-2'>Their agile development process allowed for efficient iterations and quick adjustments. Overall, it's been a fruitful collaboration. <span className='swiper-slide-2-heading'>I'm thrilled with company name work</span></p>
          <hr className='horiziontal-line-slide mt-8'/>
          <h4 className='swiper-slide-2-heading1 mt-2'>Matthew -Founder</h4>
          <img src='assets/beerrun-logo.svg'/>
          </div>
        </SwiperSlide>
      </Swiper>
              </div>
              </div>
            </div>
          </div>
          <div className='container mx-auto mt-12 mb-12' ref={sectionRef}>
            <div className='grid grid-cols-12 md:px-12 px-4'>
              <div className='lg:col-span-6 col-span-12'>
                <h2 className='s3-pg1-heading1 s16-pg1-heading'>Don’t take our word for it</h2>
                <p className='s3-pg1-text s15-pg1-text lg:mt-2'>Our partners trust us with their ideas and we reciprocate with a digital solution that becomes viral.</p>
              </div>
              <div className='lg:col-span-6 col-span-12 lg:mt-0 mt-8'>
                <div className='flex lg:justify-end justify-center'>
                  <img src='assets/tik-icon.png'/>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full footer1-bg'>
            <div className='container mx-auto'>
              <div className='grid grid-cols-12 lg:px-12 px-4 pt-16 pb-16 flex lg:gap-12'>
                <div className='lg:col-span-6 col-span-12 absollute'>
                <div className='flex'>
  <img src='assets/contact-rocket.png' className='fixed-height-img'/>
  <div className='ps-8'>
    <h2 className='footer-pg1-heading'>
      Ready to turn your vision into reality?
    </h2>
    <p className='footer-pg1-text1 lg:pt-12 md:pt-4 pt-2'>The journey starts here. We're excited to meet you.</p>
    <p className='footer-pg1-text2 lg:pt-20 md:pt-8 pt-4'>You can also find us on:</p>
    <div className='flex gap-8 pt-4'>
      <a href='#'>
    <img src='assets/icons8-whatsapp.svg'/>
    </a>
    <a href='#'>
    <img src='assets/icons8-twitter.svg'/>
    </a>
    <a href='#'>
    <img src='assets/icons8-facebook.svg'/>
    </a>
    </div>
  </div>
</div>
                </div>
                <div className='lg:col-span-6 col-span-12 relative lg:mt-0 mt-8'>
                  <div className='footer1-border'>
                    <div className='flex items-center pt-6'>
                        <p className='flex footer1-text3'>1 <img src='assets/footer1-icon-3.svg' className='ms-1'/></p>
                        <h3 className='footer1-heading2 ms-4'>How can we help you?*</h3>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-6 flex items-center'>
                      <h6 className='footer1-box ms-1'>A</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Product Strategy</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>B</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>UI/UX Design</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>C</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Web Development</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>D</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>App Development</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>E</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Enterprise Solutions</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>F</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Other</h5>
                    </div>
                    <button className='ml-11 flex footer1-btn1 items-center mt-3' onClick={openModal}>GET STARTED<img src='assets/tick.svg'className='ms-2'/></button>
                    <div className='absolute mt-1 footer-btn-position'>
                      <button className='footer1-btn2' onClick={handleScroll}><img src='assets/footerUp.svg'/></button>
                      <button className='footer1-btn3' onClick={handleScroll1}><img src='assets/footerDown.svg'/></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
<footer className="w-full footer2-bg relative " ref={sectionRef1}>
    <div className="container mx-auto lg:px-12 px-4 pt-12 pb-16">
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 lg:grid-cols-5">
              <div>
                  <h2 className="mb-6">Company</h2>
                  <ul>
                      <li className="mb-3">
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/services">
                        Services
                        </NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/portfolio">Portfolio</NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/contactUs">Contact us</NavLink>
                      </li>
                      <li className="mb-3">
                          <a href="#">Terms and Conditions</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Services</h2>
                  <ul>
                      <li className="mb-3">
                          <a href="#">App dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Web dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Wordpress dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Saas dev</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Process</h2>
                  <ul>
                      <li className="mb-3">
                          <a href="#">Design</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Ideate</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Build</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Grow</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Technologies</h2>
                  <ul className="">
                      <li className="mb-3">
                          <a href="#">Swift UI</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">CocoaTouch</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Android Jetpack</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Flutter</a>
                      </li>
                         <li className="mb-3">
                          <a href="#">Node.js</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">MongoDB</a>
                      </li>
                         <li className="mb-3">
                          <a href="#">ASP.NET</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Artificial Intelligence</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Connect</h2>
                  <ul>
                      <li class="mb-3">
                          <a href="#" className="footer2-li">+92 318 7842650</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">arslan@gmail.com</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Lahore Johar Town C - II Block Opposite UMT, Plot  38-A</a>
                      </li>
                      <li className="mb-3">
                      <a href="#" className="footer2-li">
  Location:   <span className="underline">Lahore</span>  |   <span className="underline">Pakistan</span>
</a>
                      </li>
                  </ul>
              </div>
          </div>
      <div className="sm:flex sm:items-center sm:justify-between mt-12">
          <span className="footer2-text">© 2024 <span className='footer2-span'>AppSavvy Labs</span> All Rights Reserved | Map | Policy
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
              <a href="#" className="footer2-social">
<img src='assets/footer2-img1.svg'/>
              </a>
              <a href="#" className="footer2-social ms-5">
              <img src='assets/footer2-img2.svg'/>
              </a>
              <a href="#" className="footer2-social ms-5">
                <img src='assets/footer2-img3.svg'/>
              </a>
          </div>
      </div>
    </div>
    <img src='assets/moon.svg' className='absolute right-0 footer2-position1'/>
    <img src='assets/footer-star1.svg' className='absolute footer2-position2'/>
</footer>
{isModalOpen && (
<div id="crud-modal" tabindex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" onClick={handleOverlayClick}>
    <div className="relative p-4 w-full max-w-md max-h-full mx-auto modal-width">
        <div className="modal-color relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                <button type="button" className="m-2 text-black bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal" onClick={closeModal}>
                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <div className="flex items-center justify-center md:pt-5 ps-4 pe-4 md:ps-5 md:pe-5 rounded-t dark:border-gray-600">
                <h3 className="modal-heading text-lg font-semibold text-gray-900 dark:text-white">
                    Contact Us
                </h3>
                <hr class="form-hr ms-4"/>
                </div>
                <div className='ps-4 pe-4 md:ps-5 md:pe-5 md:pt-5 pt-4'>
                  <h1 className='modal-heading-main'>See The Prodouct In Action</h1>
                  <p className='modal-text11 md:pt-3'>We'll get in touch with a FREE DEMO soon!</p>
                </div>
            <form ref={form} onSubmit={sendEmail} className="p-4 md:p-5">
                <div className="grid gap-4 mb-4 grid-cols-2">
                    <div className="col-span-2 md:pt-5">
                    <input type="text" name="user_name" id="floating_first_name" class="block py-2.5 pl-4 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none dark:text-white dark:border-black-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 peer" placeholder="Name" required />
                    </div>
                    <div className="col-span-2 md:pt-5">
                    <input type="email" name="user_email" id="floating_email" class="block py-2.5 pl-4 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 " placeholder="Email" required />
                    </div>
                    <div className="col-span-2 md:pt-5">
                    <textarea name='message' class="w-full pl-4 text-gray-700 bg-white modal-custom-border e dark:border-black-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0" placeholder="What is your project about?"></textarea>
                    </div>
                </div>
                <button type="submit" className="modal-btn text-white inline-flex items-center px-5 py-2.5">
                    SUBMIT
                </button>
            </form>
        </div>
    </div>
</div> 
)}
    </div>
  );
};

export default HomePage;
