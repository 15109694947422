import React ,{useState,useRef} from "react";
import { NavLink} from 'react-router-dom';
import '../css/portfolio.css'
import emailjs from '@emailjs/browser';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
function Services() {
  const form = useRef()
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_ci7uqmj', 'template_6zi5h0i', form.current, {
          publicKey: 'kx-y9KhqWZgdIxqxH',
        })
        .then(
          () => {
            closeModal();
            toastr.success("Email sent successfully");
          },
          (error) => {
            toastr.error(error);
          },
        );
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOverlayClick = (event) => {
      if (event.target.id === 'crud-modal') {
        closeModal();
      }
    };
    const sectionRef = useRef(null);
  const sectionRef1 = useRef(null);
  const handleScroll = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScroll1 = () => {
    if (sectionRef1.current) {
      sectionRef1.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
<div>
<div className='w-full header-section-services'>
    <div className="container mx-auto">
      <header className='pt-8 pb-8 md:px-12 px-4'>
        <nav className="navbar-1 navbar-services bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <a className="flex items-center navbar-heading" href='#'>
            App Dev &nbsp;<span className='navbar-heading-span'>Master.</span>
            </a>
            <div className="flex items-center lg:order-2">
              <button
                onClick={toggleMenu}
                type="button"
                className="toggler-btn inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded={isOpen ? "true" : "false"}
              >
                <span className="sr-only">Open main menu</span>
                <svg className={`w-6 h-6 ${isOpen ? "hidden" : "block"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                </svg>
                <svg className={`w-6 h-6 ${isOpen ? "block" : "hidden"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </button>
            </div>
            <div className={`w-full lg:flex lg:w-auto lg:order-2 ${isOpen ? "block" : "hidden"}`} id="mobile-menu-2">
              <ul className="flex navbar-custom-flex mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <NavLink className="block py-2 pr-4 pl-3" to="/">Home</NavLink>
                </li>
                <li>
                <NavLink className="block py-2 pr-4 pl-3" to="/services">
                Services
          </NavLink>
                </li>
                <li>
                <NavLink className="block py-2 pr-4 pl-3" to="/portfolio">
                Portfolio
                  </NavLink>
                </li>
                <li className="mb-2">
                <a href='#'>
                  <svg xmlns="http://www.w3.org/2000/svg" id="Component_56_1" data-name="Component 56 – 1" width="38" height="38" viewBox="0 0 54 54"><rect id="Rectangle_24465" data-name="Rectangle 24465" width="54" height="54" rx="27" fill="#8748C2"></rect><path id="Path_125981" data-name="Path 125981" d="M34.98,31.956h0v-.009l-1.249,1.242a6.577,6.577,0,0,1-6.217,1.785,21.856,21.856,0,0,1-6.342-2.84A29.327,29.327,0,0,1,16.164,28.1a29.552,29.552,0,0,1-3.741-4.544,23.02,23.02,0,0,1-2.812-5.689,6.577,6.577,0,0,1,1.644-6.718l1.458-1.464a1.045,1.045,0,0,1,1.477,0h0L18.812,14.3a1.045,1.045,0,0,1,0,1.477h0L16.1,18.494a2.211,2.211,0,0,0-.231,2.877,31.732,31.732,0,0,0,3.256,3.8A31.538,31.538,0,0,0,23.4,28.762a2.229,2.229,0,0,0,2.845-.247l2.623-2.664a1.045,1.045,0,0,1,1.477,0h0l4.629,4.643a1.043,1.043,0,0,1,.006,1.465Z" transform="translate(4.524 4.438)" fill="#fff"></path></svg>
                  </a>
                  </li>
                <li>
                <NavLink className="navbar-button block py-2 pr-4 pl-3" to="/contactUs">
                Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className='navbar-custom-center'>
              <div>
                  <svg xmlns="http://www.w3.org/2000/svg" id="Component_56_1" data-name="Component 56 – 1" width="38" height="38" viewBox="0 0 54 54"><rect id="Rectangle_24465" data-name="Rectangle 24465" width="54" height="54" rx="27" fill="#8748C2"></rect><path id="Path_125981" data-name="Path 125981" d="M34.98,31.956h0v-.009l-1.249,1.242a6.577,6.577,0,0,1-6.217,1.785,21.856,21.856,0,0,1-6.342-2.84A29.327,29.327,0,0,1,16.164,28.1a29.552,29.552,0,0,1-3.741-4.544,23.02,23.02,0,0,1-2.812-5.689,6.577,6.577,0,0,1,1.644-6.718l1.458-1.464a1.045,1.045,0,0,1,1.477,0h0L18.812,14.3a1.045,1.045,0,0,1,0,1.477h0L16.1,18.494a2.211,2.211,0,0,0-.231,2.877,31.732,31.732,0,0,0,3.256,3.8A31.538,31.538,0,0,0,23.4,28.762a2.229,2.229,0,0,0,2.845-.247l2.623-2.664a1.045,1.045,0,0,1,1.477,0h0l4.629,4.643a1.043,1.043,0,0,1,.006,1.465Z" transform="translate(4.524 4.438)" fill="#fff"></path></svg>
                  </div>
                  <div className="">
        <a href="#">
            <img width="38" height="38" src="https://appscrip.com/wp-content/uploads/2021/03/whatsapp.svg" className="attachment-thumbnail2"/>
        </a>
    </div>
                  <div className="">
        <a href="#">
            <img width="38" height="38" src="https://appscrip.com/wp-content/uploads/2021/03/skype.svg" className="attachment-thumbnail3" alt=""/>
        </a>
    </div>
          </div>
        </nav>
      </header>
    </div>
    <div className='container mx-auto py-12'>
    <section className="md:px-12 px-4">
    <div className="grid mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='hero-section-title'>OUR Portfolio</p>
    <h1 className="hero-section-heading hero-section-heading-portfolio lg:mt-4 mt-2">
    Discover our
success applications in the real-world.
    </h1>
    <button className="hero-section-btn flex items-center lg:mt-4 mt-2">
    GET IN TOUCH<img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/>
    </button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/div.banner__hero__new.svg" alt="mockup" className="lg:ps-12 object-contain h-auto w-full" />
  </div>
</div>

</section>
    </div>
    </div>
    <div class="fixed-container">
    <div class="icon-wrapper">
        <a href="#">
            <img width="35" height="34" src="https://appscrip.com/wp-content/uploads/2021/03/whatsapp.svg" className="attachment-thumbnail"/>
        </a>
    </div>
    <div class="quick-contact-slide-left">
        <h6 class="rotated-text p-0">
            <a href="#">Quick Contact</a>
        </h6>
    </div>
    <div class="icon-wrapper">
        <a href="#">
            <img width="35" height="35" src="https://appscrip.com/wp-content/uploads/2021/03/skype.svg" className="attachment-thumbnail1" alt=""/>
        </a>
    </div>
</div>
    <div className="container mx-auto pt-12 md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>TAVUS</p>
    <h2 className='s1-heading-pg3 lg:mt-4 mt-2'>Raises $18M for scalable AI voice and face cloning for personalized video</h2>
                <p className='s1-pg3-text lg:mt-4 mt-2'>Meet Tavus, the programmatic personalized video tool made for
top-performing product marketing, and sales teams.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/tavys-img.png" alt="mockup" className="lg:ps-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-8 md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s2-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>CARISTAS</p>
    <h2 className='s1-heading-pg3 lg:mt-4 mt-2'>Simplifying the car buying journey with Caristas</h2>
                <p className='s1-pg3-text lg:mt-4 mt-2'>Caristas is a revolutionary web and mobile solution that
streamlines the process of finding the perfect car.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/caristas-img.png" alt="mockup" className="lg:pe-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-12 pb-12 md:px-12 px-4">
      <div className="grid grid-cols-12 ">
  <div className="lg:col-span-6 md:col-span-8 col-span-12 md:col-start-3 lg:col-start-4 s3-pg3-portfolio-bg flex justify-center items-center">
    <div className="s3-padding-pg3 flex flex-col justify-center items-center text-center">
      <img src="assets/SVG12.svg" className="mt-6 "/>
      <h2 className="s3-heading-pg3 mt-4">A beautifully designed & functional app</h2>
      <p className="s3-text-pg3 mt-4">Arslan did an outstanding job creating Savve. Their team's expertise resulted in a highly functional app.</p>
      <div className="flex justify-center mt-4">
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
      </div>
      <div className="flex items-center mt-6">
        <div>
          <img src="assets/review-img3.svg" alt="Reviewer 1"/>
        </div>
        <div className="ps-2">
          <h5 className="s3-text2-pg3">Joseph - Founder</h5>
          <img src="assets/review-img4.svg" alt="Company Logo" />
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
      <div className="container mx-auto md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s4-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>OPAR</p>
    <h2 className='s1-heading-pg3 s4-heading-pg3 lg:mt-4 mt-2'>Find Trendy Spots and Connect with Like-Minded Locals</h2>
                <p className='s1-pg3-text s4-pg3-text lg:mt-4 mt-2'>A networking platform that connects people
and places-bringing closer to the unexplored
version of social media.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/opar-img.png" alt="mockup" className="lg:pe-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-8 md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s5-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>DOUGHFI</p>
    <h2 className='s1-heading-pg3 lg:mt-4 mt-2'>Crypto can be as fast & convenient as using cash with DoughFi</h2>
                <p className='s1-pg3-text s5-pg3-text lg:mt-4 mt-2'>A crypto wallet for seamless merchant payments.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/doughfi-img.png" alt="mockup" className="lg:pe-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-12 pb-12 md:px-12 px-4">
      <div className="grid grid-cols-12 ">
  <div className="lg:col-span-6 md:col-span-8 col-span-12 md:col-start-3 lg:col-start-4 s3-pg3-portfolio-bg flex justify-center items-center">
    <div className="s3-padding-pg3 flex flex-col justify-center items-center text-center">
      <img src="assets/SVG12.svg" className="mt-6 "/>
      <h2 className="s3-heading-pg3 mt-4">I'm thrilled with
Arslan's work</h2>
      <p className="s3-text-pg3 mt-4">Their agile development process allowed for efficient iterations and
quick adjustments. Overall, it's been a fruitful collaboration.</p>
      <div className="flex justify-center mt-4">
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
      </div>
      <div className="flex items-center mt-6">
        <div>
          <img src="assets/matthew-founder.svg" alt="Reviewer 1"/>
        </div>
        <div className="ps-2">
          <h5 className="s3-text2-pg3">Matthew - Founder</h5>
          <img src="assets/beerrun-logo1.svg" alt="Company Logo" />
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
      <div className="container mx-auto md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s6-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>SAVVE</p>
    <h2 className='s1-heading-pg3 s6-heading-pg3 lg:mt-4 mt-2'>Find your perfect
restaurant deal all across
the US</h2>
                <p className='s1-pg3-text s6-pg3-text lg:mt-4 mt-2'>Savve is a geo-targeted consumer app that is created for the
purpose of informing engaged audiences.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/savve-img.svg" alt="mockup" className="lg:pe-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-8 md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s7-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>BEER RUN</p>
    <h2 className='s1-heading-pg3 s7-heading-pg3 lg:mt-4 mt-2'>Refreshing beer at your
fingertips with on-demand
delivery</h2>
                <p className='s1-pg3-text s7-pg3-text lg:mt-4 mt-2'>With Beer Run, you can enjoy top quality brews and liquors
from leading brands at your home, in just a few taps.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/beerrun-img.svg" alt="mockup" className="lg:pe-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-12 pb-12 md:px-12 px-4">
      <div className="grid grid-cols-12 ">
  <div className="lg:col-span-6 md:col-span-8 col-span-12 md:col-start-3 lg:col-start-4 s3-pg3-portfolio-bg flex justify-center items-center">
    <div className="s3-padding-pg3 flex flex-col justify-center items-center text-center">
      <img src="assets/SVG12.svg" className="mt-6 "/>
      <h2 className="s3-heading-pg3 s8-heading-pg3 mt-4">Arslan surpassed
our expectations</h2>
      <p className="s3-text-pg3 mt-4">They suggested an agile sprint method, and provided innovative
and user friendly solutions.</p>
      <div className="flex justify-center mt-4">
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
        <img src="assets/star11.svg"/>
      </div>
      <div className="flex items-center mt-6">
        <div>
          <img src="assets/tim-reiff-co-founde.svg" alt="Reviewer 1"/>
        </div>
        <div className="ps-2">
          <h5 className="s3-text2-pg3">Tim Reiff - Co-Founder</h5>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
      <div className="container mx-auto pt-8 md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s9-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>JUST.LIVE</p>
    <h2 className='s1-heading-pg3 s7-heading-pg3 lg:mt-4 mt-2'>An interactive community
app designed to keep you
safe</h2>
                <p className='s1-pg3-text s7-pg3-text lg:mt-4 mt-2'>Just.Live is the first and only mobile app fighting for
injustices all over the world.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/just-live-img.png" alt="mockup" className="lg:pe-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-8 md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s10-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>CROWDTRACE</p>
    <h2 className='s1-heading-pg3 s10-heading-pg3 lg:mt-4 mt-2'>Curbing pandemic spread with a
biosecurity suite</h2>
                <p className='s1-pg3-text s10-pg3-text lg:mt-4 mt-2'>Crowdtrace was a COVID product that came laced with preventive
functionalities like daily symptom screening, contact tracing, and more.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/crowdtrace-img.png" alt="mockup" className="lg:pe-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-8 md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s11-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>USED ENGINES</p>
    <h2 className='s1-heading-pg3 s11-heading-pg3 lg:mt-4 mt-2'>Reengineering an auto parts
reselling platform</h2>
                <p className='s1-pg3-text s11-pg3-text lg:mt-4 mt-2'>Used Engines embarked on a mission with us to re-engineer their
auto parts reselling platform with a dedicated focus on
digitalization.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/used-engines-img.png" alt="mockup" className="lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-8 md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s12-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>ORAL ASSIST</p>
    <h2 className='s1-heading-pg3 s11-heading-pg3 lg:mt-4 mt-2'>Using oral accommodations
to make education
accessible</h2>
                <p className='s1-pg3-text s11-pg3-text lg:mt-4 mt-2'>Oral Assist is an oral accommodation and annotation-powered
application that closes the learning gap between students with
reading disabilities and their counterparts.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/oral-img.svg" alt="mockup" className="lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-8 md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s13-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>INCIDENT ALERT</p>
    <h2 className='s1-heading-pg3 s13-heading-pg3 lg:mt-4 mt-2'>Notifying users of
emergencies near them, in
real-time</h2>
                <p className='s1-pg3-text s13-pg3-text lg:mt-4 mt-2'>Incident Alert is a real-time emergency alert application that
helps users remain vigilant and safe in their locality.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/incident-img.svg" alt="mockup" className="lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-8 md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s14-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>ALBI</p>
    <h2 className='s1-heading-pg3 s13-heading-pg3 lg:mt-4 mt-2'>Helping Middle Eastern
Americans find connections in
their locality</h2>
                <p className='s1-pg3-text s14-pg3-text lg:mt-4 mt-2'>Albi is a dating platform focused on helping Middle Eastern Americans
find connections with similar preferences, near their locality.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/albi-img.svg" alt="mockup" className="lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-8 md:px-12 px-4">
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s15-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>QUANTLAB</p>
    <h2 className='s1-heading-pg3 s15-heading-pg3 lg:mt-4 mt-2'>Making a trade firm’s
internal employee portal
useful again</h2>
                <p className='s1-pg3-text s15-pg3-text lg:mt-4 mt-2'>A Houston-based trade firm witnessed an evident rise in its
intranet’s usage time after it was rebuilt from its dormant stage</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/quantlab-img.svg" alt="mockup" className="lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className="container mx-auto pt-8 pb-12 md:px-12 px-4" ref={sectionRef}>
        <div className="grid grid-cols-12  s1-pg3-portfolio-bg s16-pg3-portfolio-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s1-title-pg3'>JITT ENTERTAINMENT</p>
    <h2 className='s1-heading-pg3 s13-heading-pg3 lg:mt-4 mt-2'>Giving people a platform to be
competitive and get rewarded
for it</h2>
                <p className='s1-pg3-text s14-pg3-text lg:mt-4 mt-2'>Jitt Entertainment brought a series of competitions on mobile for
people to participate in digitally and get exciting rewards.</p>
              <button className='s1-btn1-pg3 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/jitt-img.svg" alt="mockup" className="lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
          <div className='w-full footer1-bg'>
            <div className='container mx-auto'>
              <div className='grid grid-cols-12 lg:px-12 px-4 pt-16 pb-16 flex lg:gap-12'>
                <div className='lg:col-span-6 col-span-12 absollute'>
                <div className='flex'>
  <img src='assets/contact-rocket.png' className='fixed-height-img'/>
  <div className='ps-8'>
    <h2 className='footer-pg1-heading'>
      Ready to turn your vision into reality?
    </h2>
    <p className='footer-pg1-text1 lg:pt-12 md:pt-4 pt-2'>The journey starts here. We're excited to meet you.</p>
    <p className='footer-pg1-text2 lg:pt-20 md:pt-8 pt-4'>You can also find us on:</p>
    <div className='flex gap-8 pt-4'>
      <a href='#'>
    <img src='assets/icons8-whatsapp.svg'/>
    </a>
    <a href='#'>
    <img src='assets/icons8-twitter.svg'/>
    </a>
    <a href='#'>
    <img src='assets/icons8-facebook.svg'/>
    </a>
    </div>
  </div>
</div>
                </div>
                <div className='lg:col-span-6 col-span-12 relative lg:mt-0 mt-8'>
                  <div className='footer1-border'>
                    <div className='flex items-center pt-6'>
                        <p className='flex footer1-text3'>1 <img src='assets/footer1-icon-3.svg' className='ms-1'/></p>
                        <h3 className='footer1-heading2 ms-4'>How can we help you?*</h3>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-6 flex items-center'>
                      <h6 className='footer1-box ms-1'>A</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Product Strategy</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>B</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>UI/UX Design</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>C</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Web Development</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>D</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>App Development</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>E</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Enterprise Solutions</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>F</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Other</h5>
                    </div>
                    <button className='ml-11 flex footer1-btn1 items-center mt-3'onClick={openModal}>GET STARTED<img src='assets/tick.svg'className='ms-2'/></button>
                    <div className='absolute mt-1 footer-btn-position'>
                      <button className='footer1-btn2'  onClick={handleScroll}><img src='assets/footerUp.svg'/></button>
                      <button className='footer1-btn3'  onClick={handleScroll1}><img src='assets/footerDown.svg'/></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
<footer className="w-full footer2-bg relative" ref={sectionRef1}>
    <div className="container mx-auto lg:px-12 px-4 pt-12 pb-16">
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 lg:grid-cols-5">
          <div>
                  <h2 className="mb-6">Company</h2>
                  <ul>
                      <li className="mb-3">
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/services">
                        Services
                        </NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/portfolio">Portfolio</NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/contactUs">Contact us</NavLink>
                      </li>
                      <li className="mb-3">
                          <a href="#">Terms and Conditions</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Services</h2>
                  <ul>
                      <li className="mb-3">
                          <a href="#">App dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Web dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Wordpress dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Saas dev</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Process</h2>
                  <ul>
                      <li className="mb-3">
                          <a href="#">Design</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Ideate</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Build</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Grow</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Technologies</h2>
                  <ul className="">
                      <li className="mb-3">
                          <a href="#">Swift UI</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Wordpress</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">React JS</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">React native</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Connect</h2>
                  <ul>
                      <li class="mb-3">
                          <a href="#" className="footer2-li">+92 318 7842650</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">arslan@gmail.com</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Lahore Johar Town C - II Block Opposite UMT, Plot  38-A</a>
                      </li>
                      <li className="mb-3">
                      <a href="#" className="footer2-li">
  Location:   <span className="underline">Lahore</span>  |   <span className="underline">Pakistan</span>
</a>
                      </li>
                  </ul>
              </div>
          </div>
      <div className="sm:flex sm:items-center sm:justify-between mt-12">
          <span className="footer2-text">© 2024 <span className='footer2-span'>AppSavvy Labs</span> All Rights Reserved | Map | Policy
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
              <a href="#" className="footer2-social">
<img src='assets/footer2-img1.svg'/>
              </a>
              <a href="#" className="footer2-social ms-5">
              <img src='assets/footer2-img2.svg'/>
              </a>
              <a href="#" className="footer2-social ms-5">
                <img src='assets/footer2-img3.svg'/>
              </a>
          </div>
      </div>
    </div>
    <img src='assets/moon.svg' className='absolute right-0 footer2-position1'/>
    <img src='assets/footer-star1.svg' className='absolute footer2-position2'/>
</footer>
{isModalOpen && (
<div id="crud-modal" tabindex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" onClick={handleOverlayClick}>
    <div className="relative p-4 w-full max-w-md max-h-full mx-auto modal-width">
        <div className="modal-color relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                <button type="button" className="m-2 text-black bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal" onClick={closeModal}>
                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <div className="flex items-center justify-center md:pt-5 ps-4 pe-4 md:ps-5 md:pe-5 rounded-t dark:border-gray-600">
                <h3 className="modal-heading text-lg font-semibold text-gray-900 dark:text-white">
                    Contact Us
                </h3>
                <hr class="form-hr ms-4"/>
                </div>
                <div className='ps-4 pe-4 md:ps-5 md:pe-5 md:pt-5 pt-4'>
                  <h1 className='modal-heading-main'>See The Prodouct In Action</h1>
                  <p className='modal-text11 md:pt-3'>We'll get in touch with a FREE DEMO soon!</p>
                </div>
            <form ref={form} onSubmit={sendEmail} className="p-4 md:p-5">
                <div className="grid gap-4 mb-4 grid-cols-2">
                    <div className="col-span-2 md:pt-5">
                    <input type="text" name="user_name" id="floating_first_name" class="block py-2.5 pl-4 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none dark:text-white dark:border-black-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 peer" placeholder="Name" required />
                    </div>
                    <div className="col-span-2 md:pt-5">
                    <input type="email" name="user_email" id="floating_email" class="block py-2.5 pl-4 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 " placeholder="Email" required />
                    </div>
                    <div className="col-span-2 md:pt-5">
                    <textarea name='message' class="w-full pl-4 text-gray-700 bg-white modal-custom-border e dark:border-black-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0" placeholder="What is your project about?"></textarea>
                    </div>
                </div>
                <button type="submit" className="modal-btn text-white inline-flex items-center px-5 py-2.5">
                    SUBMIT
                </button>
            </form>
        </div>
    </div>
</div> 
)}
</div>
  );
}

export default Services;
