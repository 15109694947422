import React ,{useState,useRef} from "react";
import { NavLink} from 'react-router-dom';
import '../css/services.css'
import { Navigation} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import emailjs from '@emailjs/browser';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
function Services() {
  const form = useRef()
    const [isOpen, setIsOpen] = useState(false);
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_ci7uqmj', 'template_6zi5h0i', form.current, {
          publicKey: 'kx-y9KhqWZgdIxqxH',
        })
        .then(
          () => {
            closeModal();
            toastr.success("Email sent successfully");
          },
          (error) => {
            toastr.error(error);
          },
        );
    };
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOverlayClick = (event) => {
      if (event.target.id === 'crud-modal') {
        closeModal();
      }
    };
    const sectionRef = useRef(null);
  const sectionRef1 = useRef(null);
  const handleScroll = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScroll1 = () => {
    if (sectionRef1.current) {
      sectionRef1.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
<div>
<div className='w-full header-section-services'>
    <div className="container mx-auto">
      <header className='pt-8 pb-8 md:px-12 px-4'>
        <nav className="navbar-1 navbar-services bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <a className="flex items-center navbar-heading" href='#'>
            App Dev &nbsp;<span className='navbar-heading-span'>Master.</span>
            </a>
            <div className="flex items-center lg:order-2">
              <button
                onClick={toggleMenu}
                type="button"
                className="toggler-btn inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded={isOpen ? "true" : "false"}
              >
                <span className="sr-only">Open main menu</span>
                <svg className={`w-6 h-6 ${isOpen ? "hidden" : "block"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                </svg>
                <svg className={`w-6 h-6 ${isOpen ? "block" : "hidden"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </button>
            </div>
            <div className={`w-full lg:flex lg:w-auto lg:order-2 ${isOpen ? "block" : "hidden"}`} id="mobile-menu-2">
              <ul className="flex navbar-custom-flex mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <NavLink className="block py-2 pr-4 pl-3" to="/">Home</NavLink>
                </li>
                <li>
                <NavLink className="block py-2 pr-4 pl-3" to="/services">
                Services
          </NavLink>
                </li>
                <li>
                <NavLink className="block py-2 pr-4 pl-3" to="/portfolio">
                Portfolio
                  </NavLink>
                </li>
                <li className="mb-2">
                <a href='#'>
                  <svg xmlns="http://www.w3.org/2000/svg" id="Component_56_1" data-name="Component 56 – 1" width="38" height="38" viewBox="0 0 54 54"><rect id="Rectangle_24465" data-name="Rectangle 24465" width="54" height="54" rx="27" fill="#8748C2"></rect><path id="Path_125981" data-name="Path 125981" d="M34.98,31.956h0v-.009l-1.249,1.242a6.577,6.577,0,0,1-6.217,1.785,21.856,21.856,0,0,1-6.342-2.84A29.327,29.327,0,0,1,16.164,28.1a29.552,29.552,0,0,1-3.741-4.544,23.02,23.02,0,0,1-2.812-5.689,6.577,6.577,0,0,1,1.644-6.718l1.458-1.464a1.045,1.045,0,0,1,1.477,0h0L18.812,14.3a1.045,1.045,0,0,1,0,1.477h0L16.1,18.494a2.211,2.211,0,0,0-.231,2.877,31.732,31.732,0,0,0,3.256,3.8A31.538,31.538,0,0,0,23.4,28.762a2.229,2.229,0,0,0,2.845-.247l2.623-2.664a1.045,1.045,0,0,1,1.477,0h0l4.629,4.643a1.043,1.043,0,0,1,.006,1.465Z" transform="translate(4.524 4.438)" fill="#fff"></path></svg>
                  </a>
                  </li>
                <li>
                <NavLink className="navbar-button block py-2 pr-4 pl-3" to="/contactUs">
                Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className='navbar-custom-center'>
              <div>
                  <svg xmlns="http://www.w3.org/2000/svg" id="Component_56_1" data-name="Component 56 – 1" width="38" height="38" viewBox="0 0 54 54"><rect id="Rectangle_24465" data-name="Rectangle 24465" width="54" height="54" rx="27" fill="#8748C2"></rect><path id="Path_125981" data-name="Path 125981" d="M34.98,31.956h0v-.009l-1.249,1.242a6.577,6.577,0,0,1-6.217,1.785,21.856,21.856,0,0,1-6.342-2.84A29.327,29.327,0,0,1,16.164,28.1a29.552,29.552,0,0,1-3.741-4.544,23.02,23.02,0,0,1-2.812-5.689,6.577,6.577,0,0,1,1.644-6.718l1.458-1.464a1.045,1.045,0,0,1,1.477,0h0L18.812,14.3a1.045,1.045,0,0,1,0,1.477h0L16.1,18.494a2.211,2.211,0,0,0-.231,2.877,31.732,31.732,0,0,0,3.256,3.8A31.538,31.538,0,0,0,23.4,28.762a2.229,2.229,0,0,0,2.845-.247l2.623-2.664a1.045,1.045,0,0,1,1.477,0h0l4.629,4.643a1.043,1.043,0,0,1,.006,1.465Z" transform="translate(4.524 4.438)" fill="#fff"></path></svg>
                  </div>
                  <div className="">
        <a href="#">
            <img width="38" height="38" src="https://appscrip.com/wp-content/uploads/2021/03/whatsapp.svg" className="attachment-thumbnail2"/>
        </a>
    </div>
                  <div className="">
        <a href="#">
            <img width="38" height="38" src="https://appscrip.com/wp-content/uploads/2021/03/skype.svg" className="attachment-thumbnail3" alt=""/>
        </a>
    </div>
          </div>
        </nav>
      </header>
    </div>
    <div className='container mx-auto py-12'>
    <section className="md:px-12 px-4">
    <div className="grid mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='hero-section-title'>OUR SERVICES</p>
    <h1 className="hero-section-heading hero-section-heading-services lg:mt-4 mt-2">
    Delivering exceptional digital Services.
    </h1>
    <p className="hero-section-text hero-section-text-services lg:mt-4 mt-2">
    Solve complex digital product challenges with full cycle product development services that blend in human-centered design and technology.
    </p>
    <button className="hero-section-btn flex items-center lg:mt-4 mt-2">
    GET IN TOUCH<img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/>
    </button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/services-hero.png" alt="mockup" className="lg:ps-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
</div>

</section>
    </div>
    </div>
    <div class="fixed-container">
    <div class="icon-wrapper">
        <a href="#">
            <img width="35" height="34" src="https://appscrip.com/wp-content/uploads/2021/03/whatsapp.svg" className="attachment-thumbnail"/>
        </a>
    </div>
    <div class="quick-contact-slide-left">
        <h6 class="rotated-text p-0">
            <a href="#">Quick Contact</a>
        </h6>
    </div>
    <div class="icon-wrapper">
        <a href="#">
            <img width="35" height="35" src="https://appscrip.com/wp-content/uploads/2021/03/skype.svg" className="attachment-thumbnail1" alt=""/>
        </a>
    </div>
</div>
    <div className='w-full relative'>
      <div className='container mx-auto'>
        
        <div className='grid flex mx-auto md:px-12 pt-12 pb-12 px-4  lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1'>
        <div className='lg:col-span-12'>
        <p className='s3-pg1-title'>PRODUCT PARTNER</p>
        </div>
        <div className="lg:col-span-6 col-span-12 mr-8">
          <h2 className='s3-pg1-heading s3-pg2-heading'>Full-cycle product development</h2>
          </div>
          <div className="lg:col-span-6 col-span-12">
          <p className='s3-pg1-text s3-pg2-text1 lg:mt-2'>Create new software or improve your existing system with our full
cycle product development services. We are your development
partners with a proven track record of building high-quality,
scalable, and robust software from frontend development
to backend infrastructure.</p>
<a className='s5-pg1-btn flex items-center'>LET’S CONNECT  <img src='assets/s5-arrow.svg' className='ms-4'/></a>
          </div>
        </div>
      </div>
      <img src='assets/moon.svg' className='moon-pg1-positon moon-pg2-positon'/>
      <img src="assets/bottom-star.svg" className="star-pg2-position"/>
      </div>
      <div className="w-full pg2-s3-bg">
        <div className="container  mx-auto">
        <div className="md:px-12 px-4 flex justify-between">
    <div>
        <h3 className="heading2-s3-pg2">Ideate</h3>
    </div>
    <div>
        <h3 className="heading2-s3-pg2">Design</h3>
    </div>
    <div>
        <h3 className="heading2-s3-pg2">Development</h3>
    </div>
    <div>
        <h3 className="heading2-s3-pg2">Grow</h3>
    </div>
</div>

        </div>
      </div>
      <div className='w-full'>
      <div className='container mx-auto'>
        
        <div className='grid flex mx-auto md:px-12 pt-12 pb-12 px-4  lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1'>
          <div className="col-span-12">
          <h2 className='s3-pg1-heading s3-pg2-heading'>Ideate</h2>
          <p className='s3-pg1-text s4-pg2-text1 mt-2'>Refining ideas that solve real users’ problems is the first step to creating a useful product. Our
brainstorming, research, and design thinking sessions are engineered to help you mitigate
business risks from day one.</p>
<a className='s5-pg1-btn flex items-center mt-2'>LET’S CONNECT  <img src='assets/arrow-right-long-primary.svg' className='ms-4'/></a>
          </div>
        </div>
      </div>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-12 md:px-12 px-4 md:gap-8 gap-0">
            <div className="lg:col-span-4 md:col-span-6 col-span-12">
                <div className="card-services-pg2">
                    <img src="assets/services-img1.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Discovery Workshop</h3>
                    <p className="card-services-pg2-text mt-4">Set business goals, discuss project
delivery logistics, and fix the timeline and
budget of your digital product
development services investment.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img2.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Product validation</h3>
                    <p className="card-services-pg2-text mt-4">Validate your digital product idea against
every market, technical, and business
angle to launch a market-ready product.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img3.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Product Design Sprint</h3>
                    <p className="card-services-pg2-text mt-4">Ideate, prototype, and run hypothesis tests on your digital product by involving real users in the concept finalization process.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img4.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Product Strategy</h3>
                    <p className="card-services-pg2-text mt-4">Devise short and long-term strategies to
define the full cycle app development
milestones that would chart a successful
launch.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img5.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Product Management</h3>
                    <p className="card-services-pg2-text mt-4">Set up the organizational function to
streamline every stage of the product
lifecycle from development to market
positioning.</p>
                </div>
            </div>
        </div>
      </div>
      <div className="container mx-auto pt-12 md:px-12 px-4">
        <div className="grid grid-cols-12  s6-pg2-services-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s6-title-pg2'>TAVUS</p>
    <h2 className='s13-pg1-heading s6-heading-pg2 lg:mt-4 mt-2'>Raises $18M for scalable AI voice and face cloning for personalized video</h2>
                <p className='s3-pg1-text s6-text-pg2 lg:mt-4 mt-2'>Meet Tavus, the programmatic personalized video tool made for
top-performing product marketing, and sales teams.</p>
              <button className='s14-btn1-pg1 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/tavys-img.png" alt="mockup" className="lg:ps-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className='w-full'>
      <div className='container relative pt-12 mx-auto'>
        
        <div className='grid flex mx-auto md:px-12 pt-12 pb-12 px-4  lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1'>
          <div className="col-span-12">
          <h2 className='s3-pg1-heading s3-pg2-heading'>Design</h2>
          <p className='s3-pg1-text s4-pg2-text1 mt-2'>Inspiring your users to act by transforming their in-product journey is where our design efforts’
modus operandi lies. From understanding your users in-depth to designing experiences which
would make them loyal, we do it all.</p>
<a className='s5-pg1-btn flex items-center mt-2'>LET’S CONNECT  <img src='assets/arrow-right-long-primary.svg' className='ms-4'/></a>
          </div>
        </div>
        <img src="assets/Comet.svg" className="absolute design-section-pg2-position"/>
      </div>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-12 md:px-12 px-4 md:gap-8 gap-0">
            <div className="lg:col-span-4 md:col-span-6 col-span-12">
                <div className="card-services-pg2">
                    <img src="assets/services-img6.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Branding</h3>
                    <p className="card-services-pg2-text mt-4">Digital product design services that build a
brand that gives you a competitive edge
while getting you closer to your resonance
goals.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img7.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">UI/UX Research and Design</h3>
                    <p className="card-services-pg2-text mt-4">Get an insights-driven design system that
is a reflection of your users perceptions,
goals, and their expectations from your
mobile app.</p>
                </div>
            </div>
        </div>
      </div>
      <div className="container mx-auto pt-12 md:px-12 px-4">
        <div className="grid grid-cols-12  s6-pg2-services-bg s10-pg2-services-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s6-title-pg2'>OPAR</p>
    <h2 className='s13-pg1-heading s10-heading-pg2 lg:mt-4 mt-2'>Find Trendy Spots and Connect with Like-Minded Locals</h2>
                <p className='s3-pg1-text s13-text-pg2 lg:mt-4 mt-2'>A networking platform that connects people
and places-bringing closer to the unexplored
version of social media.</p>
              <button className='s14-btn1-pg1 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/opar-img.png" alt="mockup" className="lg:pe-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className='w-full'>
      <div className='container mx-auto'>
        
        <div className='grid flex mx-auto md:px-12 pt-12 pb-12 px-4  lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1'>
          <div className="col-span-12">
          <h2 className='s3-pg1-heading s3-pg2-heading'>Developement</h2>
          <p className='s3-pg1-text s4-pg2-text1 mt-2'>Engineering custom software product development is what we do best. Our team excels at
integrating technology, human-centered experience, and market conditions together to build a
robust, scalable, and viral digital product.</p>
<a className='s5-pg1-btn flex items-center mt-2'>LET’S CONNECT  <img src='assets/arrow-right-long-primary.svg' className='ms-4'/></a>
          </div>
        </div>
      </div>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-12 md:px-12 px-4 md:gap-8 gap-0">
            <div className="lg:col-span-4 md:col-span-6 col-span-12">
                <div className="card-services-pg2">
                    <img src="assets/services-img8.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">MVP Development</h3>
                    <p className="card-services-pg2-text mt-4">Test the market with our MVP development
for startups services and guarantee your
digital product’s success even before its
full-fledged launch.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img9.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Mobile App Development</h3>
                    <p className="card-services-pg2-text mt-4">Get your brand on the device that the world
uses with our native, cross-platform, PWA,
and wearable development expertise.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img10.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Backend development</h3>
                    <p className="card-services-pg2-text mt-4">Build a robust, scalable, and highly secure
backend architecture that effortlessly
grows with your business.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img11.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Low-code development</h3>
                    <p className="card-services-pg2-text mt-4">Develop an app or website with half the
efforts, resources, monetary investments
and the same quality standards as built-
from-scratch platforms.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img12.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Blockchain</h3>
                    <p className="card-services-pg2-text mt-4">Decentralize your processes, build
trustless ecosystems, or create immutable
architecture - all through our blockchain
development services.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img13.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Wordpress development</h3>
                    <p className="card-services-pg2-text mt-4">Put your data to work and unlock hidden
insights, automate processes & drive
innovation with our wordpress solution at the same place.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img14.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Cloud</h3>
                    <p className="card-services-pg2-text mt-4">Improve the scalability, security of your
digital solutions for businesses and
individuals without facing a financial dent.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img15.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Web3</h3>
                    <p className="card-services-pg2-text mt-4">Build trustless, ubiquitous connectivity, and
decentralization powered solutions that are
redefining the internet.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img16.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">AR/VR</h3>
                    <p className="card-services-pg2-text mt-4">Close the gap between the real and virtual
world with AR/VR and Mixed Reality
solutions built for highly interactive
businesses.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img17.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">IoT</h3>
                    <p className="card-services-pg2-text mt-4">Connect devices to build an ecosystem of
machines linked together in a way that they
communicate and take real-time decisions.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img18.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Web development</h3>
                    <p className="card-services-pg2-text mt-4">Cut through the crowded website industry
noise with a web platform and website that
is loved by both users and the search
engines.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img19.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Software development</h3>
                    <p className="card-services-pg2-text mt-4">Become digital-first by launching your
software on every platform your user is
active on - phones, tablet, smartwatch, and
desktop.</p>
                </div>
            </div>
        </div>
      </div>
      <div className="container mx-auto pt-12 md:px-12 px-4">
        <div className="grid grid-cols-12  s6-pg2-services-bg s11-pg2-services-bg">
  <div className="mr-auto place-self-center lg:col-span-6 col-span-12">
    <p className='s6-title-pg2'>DOUGHFI</p>
    <h2 className='s13-pg1-heading s10-heading-pg2 lg:mt-4 mt-2'>Crypto can be as fast & convenient as using cash with DoughFi</h2>
                <p className='s3-pg1-text s15-text-pg2 lg:mt-4 mt-2'>A crypto wallet for seamless merchant payments.</p>
              <button className='s14-btn1-pg1 flex items-center lg:mt-4 mt-2'  onClick={openModal}>GET STARTED <img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/></button>
  </div>
  <div className="lg:mt-0 lg:col-span-6 lg:flex col-span-12 lg:mt-0 mt-16">
  <img src="assets/doughfi-img.png" alt="mockup" className="lg:pe-12 lg:pt-12 object-contain h-auto w-full" />
  </div>
        </div>
      </div>
      <div className='w-full'>
      <div className='container mx-auto pt-12 relative'>
        
        <div className='grid flex mx-auto md:px-12 pt-12 pb-12 px-4  lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1'>
          <div className="col-span-12">
          <h2 className='s3-pg1-heading s3-pg2-heading'>Maintain & Grow</h2>
          <p className='s3-pg1-text s4-pg2-text1 mt-2'>Maintaining your position in the competitive digital space requires a constant upkeep of your
software. Our mobile app developers ensure that your application remains on top of the users’
minds with relevant designs and functionalities.</p>
<a className='s5-pg1-btn flex items-center mt-2'>LET’S CONNECT  <img src='assets/arrow-right-long-primary.svg' className='ms-4'/></a>
          </div>
        </div>
        <img src="assets/Comet.svg" className="absolute design-section-pg2-position"/>
      </div>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-12 md:px-12 px-4 md:gap-8 gap-0">
            <div className="lg:col-span-4 md:col-span-6 col-span-12">
                <div className="card-services-pg2">
                    <img src="assets/services-img20.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">QA</h3>
                    <p className="card-services-pg2-text mt-4">Ensure your software is battle-tested
against every user and business
requirement with thorough quality
assurance efforts.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img21.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Cybersecurity</h3>
                    <p className="card-services-pg2-text mt-4">Make your software hack-proof with a
security-first development approach that is
known to build compliance-friendly digital
products.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img22.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Growth marketing</h3>
                    <p className="card-services-pg2-text mt-4">For an application to become viral, you will
need to put in a dedicated marketing and
promotion effort. That is what we are here
for.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img23.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">DevOps</h3>
                    <p className="card-services-pg2-text mt-4">Enable better collaboration between your
development and operations teams to help
them expedite the development cycle with
high quality guarantee.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img24.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Digital transformation</h3>
                    <p className="card-services-pg2-text mt-4">Remain competitive and generate better
ROI by transforming your people,
processes, platforms, and policies.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img25.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">Staff augmentation</h3>
                    <p className="card-services-pg2-text mt-4">Looking for a single resource to work
alongside your development team? Hire our
developers in less than 48 working hours.</p>
                </div>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 md:pt-0 pt-4">
                <div className="card-services-pg2">
                    <img src="assets/services-img26.svg" className="pt-3"/>
                    <h3 className="card-services-pg2-heading mt-8">App modernization</h3>
                    <p className="card-services-pg2-text mt-4">Keep your software relevant and useful by
updating and upgrading to new design
systems, functions, and technologies.</p>
                </div>
            </div>
        </div>
      </div>
      <div className="w-full s17-services-pg2-bg mt-12">
      <div className='container mx-auto py-12'>
    <section className="md:px-12 px-4">
    <div className="grid mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1">
  <div className="mr-auto place-self-center lg:col-span-8 col-span-12">
    <h1 className="hero-section-heading lg:mt-4 mt-2">
    Get a free consulting call from our in-house technology experts
    </h1>
    <button className="hero-section-btn flex items-center lg:mt-4 mt-2">
    GET IN TOUCH<img src='assets/arrow-right-long.svgfill.svg' className='ms-4'/>
    </button>
  </div>
  <div className="lg:col-span-4 col-span-12 lg:mt-0 mt-16 section17-img-center-services">
  <img src="assets/brilliance_img.png" alt="mockup" className="" />
  </div>
</div>

</section>
    </div>
      </div>
      <div className='w-full s18-services-pg2-bg'>
      <div className='container mx-auto pt-12 relative'>
        
        <div className='grid flex mx-auto md:px-12 pt-12 pb-12 px-4  lg:gap-8 xl:gap-0 lg:grid-cols-12 grid-cols-1'>
          <div className="col-span-12">
          <h2 className='s3-pg1-heading s3-pg2-heading'>Industries we serve</h2>
          <p className='s3-pg1-text s4-pg2-text1 mt-2'>We use technology to not just transform your business but also establish you as the torchbearer
of digital change in your industry.</p>
          </div>
          <div className="col-span-12 mt-12">
            <img src="assets/industries.svg"/>
          </div>
        </div>
        <img src="assets/star-fair.svg" className="absolute design-section-pg2-position"/>
      </div>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-12 md:px-12 pt-12 px-4 lg:gap-8 gap-0">
            <div className="lg:col-span-6 col-span-12">
                <h3 className="s18-pg2-heading">For Startups</h3>
                <p className="s18-pg2-text lg:mt-4 mt-2">Starting a new venture can be exciting, but also challenging. With our
custom startup app development solutions, we help young startups
turn their ideas into reality. From concept to launch, we work closely
with you to ensure your app is tailored to your target market.</p>
            </div>
            <div className="lg:col-span-6 col-span-12 lg:mt-0 mt-8">
            <h3 className="s18-pg2-heading">For Enterprises</h3>
                <p className="s18-pg2-text lg:mt-4 mt-2">In today's fast-paced business world, you need to be ahead of the
curve. Whether you are looking to increase customer engagement or
modernize your legacy solution, our tailored enterprise app
development services can help achieve your digitalization goals.</p>
            </div>
        </div>
      </div>
      <div className='container mx-auto mt-12 mb-12'>
            <div className='grid grid-cols-12 md:px-12 px-4'>
              <div className='lg:col-span-6 col-span-12'>
              <h2 className="hero-section-heading hero-section-heading-services123 lg:mt-4 mt-2">Client Testimonials</h2>
              <p className="s3-pg1-text s4-pg2-text1 mt-2">See how companies like yours used AppSavvy's app platform to help them achieve their business goals</p>
              </div>
              <div className='lg:col-span-6 col-span-12 lg:mt-0 mt-8'>
                <div className='flex lg:justify-end justify-center'>
                  <img src='assets/tik-icon.png'/>
                </div>
              </div>
            </div>
          </div>
          <div className="container mx-auto mb-12" ref={sectionRef}>
          <div className=" service-slider">
          <Swiper
          modules={[Navigation]}
      spaceBetween={20}
      navigation
      breakpoints={{
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1023: {
          slidesPerView: 3,
        },
      }}
    >
      <SwiperSlide>
      <div className="review-card-pg2 h-full flex flex-col justify-between">
          <div className="flex-1 flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between">
                <div>
                  <img src="assets/review-img1.svg" alt="Review 1" />
                </div>
                <div className="flex card-inner-star">
                  <img src="assets/review-img2.svg" alt="Star" />
                  <p className="inner-section-text-pg2">4.8</p>
                </div>
              </div>
              <h3 className="review-main-heading-pg2 mt-6">Ulingoo</h3>
              <p className="review-main-text-pg2 mt-4">
              AppSavvy is great to work with. They handled my complex application and, after a couple of revisions, delivered exactly what I asked for.
              </p>
            </div>
          </div>
          <div className="flex items-center mt-8">
            <div>
              <img src="assets/review-img3.svg" alt="Reviewer 1" className="imag-review-pg2" />
            </div>
            <div className="ps-2">
              <h5 className="review-name-pg2">Muyudeen Alharazim</h5>
              <img src="assets/review-img4.svg" alt="Company Logo" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="review-card-pg2 h-full flex flex-col justify-between">
          <div className="flex-1 flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between">
                <div>
                  <img src="assets/review-img1.svg" alt="Review 2" />
                </div>
                <div className="flex card-inner-star">
                  <img src="assets/review-img2.svg" alt="Star" />
                  <p className="inner-section-text-pg2">5.0</p>
                </div>
              </div>
              <h3 className="review-main-heading-pg2 mt-6">ChatterQuant</h3>
              <p className="review-main-text-pg2 mt-4">
              AppSavvy was great, providing high-quality work with effective communication despite delays.
              </p>
            </div>
          </div>
          <div className="flex items-center mt-8">
            <div>
              <img src="assets/matthew-founder.svg" alt="Reviewer 2"  className="imag-review-pg2"/>
            </div>
            <div className="ps-2">
              <h5 className="review-name-pg2">Guruexaminer</h5>
              <img src="assets/beerrun-logo1.svg" alt="Company Logo" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="review-card-pg2 h-full flex flex-col justify-between">
          <div className="flex-1 flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between">
                <div>
                  <img src="assets/review-img1.svg" alt="Review 1" />
                </div>
                <div className="flex card-inner-star">
                  <img src="assets/review-img2.svg" alt="Star" />
                  <p className="inner-section-text-pg2">4.8</p>
                </div>
              </div>
              <h3 className="review-main-heading-pg2 mt-6">PinkTok</h3>
              <p className="review-main-text-pg2 mt-4">Enjoy working with AppSavvy team. They consistently deliver exactly as needed, fostering a great relationship over multiple collaborations.
              </p>
            </div>
          </div>
          <div className="flex items-center mt-8">
            <div>
              <img src="assets/tim-reiff-co-founde.svg" alt="Reviewer 1" className="imag-review-pg2" />
            </div>
            <div className="ps-2">
              <h5 className="review-name-pg2">Allan Maman</h5>
              <img src="assets/caristas1.svg" alt="Company Logo" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="review-card-pg2 h-full flex flex-col justify-between">
          <div className="flex-1 flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between">
                <div>
                  <img src="assets/review-img1.svg" alt="Review 3" />
                </div>
                <div className="flex card-inner-star">
                  <img src="assets/review-img2.svg" alt="Star" />
                  <p className="inner-section-text-pg2">4.9</p>
                </div>
              </div>
              <h3 className="review-main-heading-pg2 mt-6">Tractivity</h3>
              <p className="review-main-text-pg2 mt-4">
              AppSavvy converted my iOS project to Android. Despite delays, they dedicatedly delivered high-quality work. Will collaborate again.
              </p>
            </div>
          </div>
          <div className="flex items-center mt-8">
            <div>
              <img src="assets/review-img3.svg" alt="Reviewer 3" className="imag-review-pg2" />
            </div>
            <div className="ps-2">
              <h5 className="review-name-pg2">Matthew - Founder</h5>
              <img src="assets/review-img4.svg" alt="Company Logo" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="review-card-pg2 h-full flex flex-col justify-between">
          <div className="flex-1 flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between">
                <div>
                  <img src="assets/review-img1.svg" alt="Review 3" />
                </div>
                <div className="flex card-inner-star">
                  <img src="assets/review-img2.svg" alt="Star" />
                  <p className="inner-section-text-pg2">4.5</p>
                </div>
              </div>
              <h3 className="review-main-heading-pg2 mt-6">Dragonlawns</h3>
              <p className="review-main-text-pg2 mt-4">
AppSavvy quickly understood my concept, offering patient guidance on requirements and milestones. I trust them and plan more projects together.
              </p>
            </div>
          </div>
          <div className="flex items-center mt-8">
            <div>
              <img src="assets/tim-reiff-co-founde.svg" alt="Reviewer 3" className="imag-review-pg2" />
            </div>
            <div className="ps-2">
              <h5 className="review-name-pg2">Mike Fern</h5>
              <img src="assets/caristas1.svg" alt="Company Logo" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      </Swiper>
    </div>
          </div>
          <div className='w-full footer1-bg'>
            <div className='container mx-auto'>
              <div className='grid grid-cols-12 lg:px-12 px-4 pt-16 pb-16 flex lg:gap-12'>
                <div className='lg:col-span-6 col-span-12 absollute'>
                <div className='flex'>
  <img src='assets/contact-rocket.png' className='fixed-height-img'/>
  <div className='ps-8'>
    <h2 className='footer-pg1-heading'>
      Ready to turn your vision into reality?
    </h2>
    <p className='footer-pg1-text1 lg:pt-12 md:pt-4 pt-2'>The journey starts here. We're excited to meet you.</p>
    <p className='footer-pg1-text2 lg:pt-20 md:pt-8 pt-4'>You can also find us on:</p>
    <div className='flex gap-8 pt-4'>
      <a href='#'>
    <img src='assets/icons8-whatsapp.svg'/>
    </a>
    <a href='#'>
    <img src='assets/icons8-twitter.svg'/>
    </a>
    <a href='#'>
    <img src='assets/icons8-facebook.svg'/>
    </a>
    </div>
  </div>
</div>
                </div>
                <div className='lg:col-span-6 col-span-12 relative lg:mt-0 mt-8'>
                  <div className='footer1-border'>
                    <div className='flex items-center pt-6'>
                        <p className='flex footer1-text3'>1 <img src='assets/footer1-icon-3.svg' className='ms-1'/></p>
                        <h3 className='footer1-heading2 ms-4'>How can we help you?*</h3>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-6 flex items-center'>
                      <h6 className='footer1-box ms-1'>A</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Product Strategy</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>B</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>UI/UX Design</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>C</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Web Development</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>D</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>App Development</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>E</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Enterprise Solutions</h5>
                    </div>
                    <div className='footer1-tab1 ml-11 mt-2 flex items-center'>
                      <h6 className='footer1-box ms-1'>F</h6>
                      <h5 className='ps-3 footer1-box-heading pt-1'>Other</h5>
                    </div>
                    <button className='ml-11 flex footer1-btn1 items-center mt-3'onClick={openModal}>GET STARTED<img src='assets/tick.svg'className='ms-2'/></button>
                    <div className='absolute mt-1 footer-btn-position'>
                      <button className='footer1-btn2' onClick={handleScroll}><img src='assets/footerUp.svg'/></button>
                      <button className='footer1-btn3' onClick={handleScroll1}><img src='assets/footerDown.svg'/></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
<footer className="w-full footer2-bg relative" ref={sectionRef1}>
    <div className="container mx-auto lg:px-12 px-4 pt-12 pb-16">
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 lg:grid-cols-5">
          <div>
                  <h2 className="mb-6">Company</h2>
                  <ul>
                      <li className="mb-3">
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/services">
                        Services
                        </NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/portfolio">Portfolio</NavLink>
                      </li>
                      <li className="mb-3">
                        <NavLink to="/contactUs">Contact us</NavLink>
                      </li>
                      <li className="mb-3">
                          <a href="#">Terms and Conditions</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Services</h2>
                  <ul>
                      <li className="mb-3">
                          <a href="#">App dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Web dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Wordpress dev</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Saas dev</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Process</h2>
                  <ul>
                      <li className="mb-3">
                          <a href="#">Design</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Ideate</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Build</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Grow</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Technologies</h2>
                  <ul className="">
                      <li className="mb-3">
                          <a href="#">Swift UI</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Wordpress</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">React JS</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">React native</a>
                      </li>
                  </ul>
              </div>
              <div>
                  <h2 className="mb-6">Connect</h2>
                  <ul>
                      <li class="mb-3">
                          <a href="#" className="footer2-li">+92 318 7842650</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">arslan@gmail.com</a>
                      </li>
                      <li className="mb-3">
                          <a href="#">Lahore Johar Town C - II Block Opposite UMT, Plot  38-A</a>
                      </li>
                      <li className="mb-3">
                      <a href="#" className="footer2-li">
  Location:   <span className="underline">Lahore</span>  |   <span className="underline">Pakistan</span>
</a>
                      </li>
                  </ul>
              </div>
          </div>
      <div className="sm:flex sm:items-center sm:justify-between mt-12">
          <span className="footer2-text">© 2024 <span className='footer2-span'>AppSavvy Labs</span> All Rights Reserved | Map | Policy
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
              <a href="#" className="footer2-social">
<img src='assets/footer2-img1.svg'/>
              </a>
              <a href="#" className="footer2-social ms-5">
              <img src='assets/footer2-img2.svg'/>
              </a>
              <a href="#" className="footer2-social ms-5">
                <img src='assets/footer2-img3.svg'/>
              </a>
          </div>
      </div>
    </div>
    <img src='assets/moon.svg' className='absolute right-0 footer2-position1'/>
    <img src='assets/footer-star1.svg' className='absolute footer2-position2'/>
</footer>
{isModalOpen && (
<div id="crud-modal" tabindex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" onClick={handleOverlayClick}>
    <div className="relative p-4 w-full max-w-md max-h-full mx-auto modal-width">
        <div className="modal-color relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                <button type="button" className="m-2 text-black bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal" onClick={closeModal}>
                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <div className="flex items-center justify-center md:pt-5 ps-4 pe-4 md:ps-5 md:pe-5 rounded-t dark:border-gray-600">
                <h3 className="modal-heading text-lg font-semibold text-gray-900 dark:text-white">
                    Contact Us
                </h3>
                <hr class="form-hr ms-4"/>
                </div>
                <div className='ps-4 pe-4 md:ps-5 md:pe-5 md:pt-5 pt-4'>
                  <h1 className='modal-heading-main'>See The Prodouct In Action</h1>
                  <p className='modal-text11 md:pt-3'>We'll get in touch with a FREE DEMO soon!</p>
                </div>
            <form ref={form} onSubmit={sendEmail} className="p-4 md:p-5">
                <div className="grid gap-4 mb-4 grid-cols-2">
                    <div className="col-span-2 md:pt-5">
                    <input type="text" name="user_name" id="floating_first_name" class="block py-2.5 pl-4 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none dark:text-white dark:border-black-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 peer" placeholder="Name" required />
                    </div>
                    <div className="col-span-2 md:pt-5">
                    <input type="email" name="user_email" id="floating_email" class="block py-2.5 pl-4 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 " placeholder="Email" required />
                    </div>
                    <div className="col-span-2 md:pt-5">
                    <textarea name='message' class="w-full pl-4 text-gray-700 bg-white modal-custom-border e dark:border-black-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0" placeholder="What is your project about?"></textarea>
                    </div>
                </div>
                <button type="submit" className="modal-btn text-white inline-flex items-center px-5 py-2.5">
                    SUBMIT
                </button>
            </form>
        </div>
    </div>
</div> 
)}
</div>
  );
}

export default Services;
